import React from 'react';

import Constants from './Constants.js';

export default class PrivacyPolicy extends React.Component {
  render() {
    var llc = Constants.llc;

    return (
      <div>
        <h1>Privacy Policy</h1>

        <p>Mighty Words or www.mightywords.co (hereforth referred to as the 
        "Site"), operated by {llc} (also referred to as "we", "us", "our"), 
        is dedicated to sharing our love of words and the Engligh language to 
        inspire the next generation of students. We take the privacy of our 
        users very seriously. This policy has been adopted to address the 
        collection and use of information from all {llc} sites. We collect 
        and use information from users in order to efficiently and 
        effectively provide our services. We will never sell or rent users’ 
        personal information to third-parties as part of providing our 
        services. Please do not hesitate to contact us if you have any 
        questions about our privacy practices.</p>

        <h4>COPPA Compliance</h4>

        <p>Students under the age of 13 ("children" or "child") may access the 
        Site. Therefore, our information practices comply with the federal 
        Children's Online Privacy Protection Act ("COPPA"). COPPA requires 
        that we inform you of our practices with regards to our collection, 
        use, or disclosure of personal information from students under the 
        age of 13, and to obtain verifiable parental consent prior to 
        collecting, using, or disclosing any such information.</p>

        <p>If we discover we have collected information from a child in a manner 
        inconsistent with COPPA’s requirements, we will either seek the 
        parent’s consent for that collection or delete the information. We 
        won’t require a child to disclose more information than is 
        reasonably necessary to participate in an activity.</p>

        <p><strong>Verifiable Parental Consent:</strong> In order for a child to access the 
        Site, a parent or legal guardian ("Parent") must provide verifiable 
        parental consent. {llc} generally obtains verifiable parental consent 
        prior to collecting, using, or disclosing any personal information 
        from children. There are limited instances where we may collect 
        information without parental consent in compliance with COPPA, 
        including:</p>

        <ol>
        <li>To protect the security or integrity of our sites, applications, 
        and other technology.</li>
        <li>We may collect contact information in order to respond to a 
        specific request from a student on a one-time basis.</li>
        </ol>

        <p><strong>Participation in Mighty Words:</strong> The Site, operated by {llc}, 
        collects different types of information from Students and Parents:</p>

        <ol>
        <li>Contact Information: Parents may provide us with their name, email 
        address, and/or phone number while interacting with our sites and 
        services. For instance, you may provide contact information in order 
        to sign up for an account, sign up for email updates, complete a 
        purchase, or submit a contact form or support request. We also ask 
        students for their email address and the name by which they want to 
        be addressed to personalize the student’s experience on the site. 
        We will also never share your contact information to other users on 
        the site. We will never share, sell or rent your contact information 
        to third parties except when required by law.</li>
        <li>Student Performance Data: As Students use the Site, their 
        interactions within the Site, as well as their progress and 
        performance may be stored. This data is necessary to make learning 
        effective within the site. We use this data in order to fulfill the 
        educational purposes of the product and to analyze and improve the 
        product. We never share this data with other users or third parties.</li>
        <li>Payment Information: Some of our products may be purchased through 
        a monthly subscription service. We collect certain information from a 
        customer who is purchasing a product and/or service using our online 
        ordering system. Such information may include: name, billing address, 
        credit card information, other information related to other forms of 
        payment (e.g., Paypal), email address, and phone number. This 
        information is used to process purchases and to contact you if there 
        is an issue with your purchase. We will share this information with 
        our credit card payment processor. We will store all of this 
        information on our secure server, except for your credit card 
        information, which is only retained until your payment has been 
        processed and is not stored thereafter; however, this information may 
        be retained by our credit card processor in order to process 
        additional transactions. You will be notified via email of each 
        transaction. We will not use this information for any purpose other 
        than to process your purchase, and will not share this information 
        with any other third party, except as described above, or as may be 
        required by law.</li>
        </ol>

        <h4>Information Collected Automatically:</h4>

        <p><strong>Device and Use Data:</strong> Like most websites, we automatically collect 
        certain technical information from your browser or device when you 
        visit our website. We collect technical information such as your 
        device’s IP address, socket id, web browser version, and operating 
        system. We collect information on which pages users visit and how you 
        use the website. We use this information to optimize your online 
        experience for your device, to diagnose any problems with our server, 
        to improve the performance of our website, and to provide support for 
        our internal operations.</p>

        <p><strong>Web Beacons:</strong> A "web beacon" is a small image that appears in 
        emails we send to users in order to track whether an email is opened. 
        We use this information in order to analyze trends, improve the 
        content of our emails, and update our mailing lists.</p>

        <p><strong>Cookies:</strong> A "cookie" is a small piece of data placed on your 
        computer by us. Within your web browser, cookies placed by our 
        website can only be accessed by our website. The data collected using 
        cookies typically includes information such as your IP address or 
        mobile identifier, the type of computer operating system, the web 
        browser, the frequency with which the user visits various parts of 
        our sites or applications, and information regarding the online or 
        mobile service provider. We use cookies to maintain session and 
        preference information to improve the use of the website services 
        such as analytics and to place advertisements for our products and 
        services. You may set your web browser to decline our cookies; 
        however this will render large portions of our site unusable.</p>

        <p>We also allow cookies to be placed on your computer by a third party 
        for the purpose of website analytics. This allows a third party to 
        collect data about your use of our website; however, no personally 
        identifiable information is collected. Our third party service 
        providers may set cookies on a user’s web browser and/or obtain 
        information about the fact that a web browser visited the Site from a 
        certain IP address. Third parties cannot collect any other personally 
        identifiable information from our websites unless you provide it to 
        them directly.</p>

        <p>We generally use the data collected from third-party cookies in the 
        following ways:</p>

        <ul>
        <li>to report, in aggregate, how our visitors find and use our website;</li>
        <li>to understand overall visitor behavior; and</li>
        <li>to inform how we update and improve our website.</li>
        </ul>

        <p>The list of third-party operators who collect this information for 
        these purposes on our sites and applications are Alphabet Inc. and 
        Meta, Inc. For any questions about how we or any third-party uses 
        your data, please do not hesitate to contact us.</p>

        <h4>How Else May {llc} Use Collected Information?</h4>

        <p>Consistent with the information collection practices outlined above, 
        we share information with service providers if necessary for them to 
        perform a business, professional, or technology support function for 
        us. We may combine the different types of information we collect in 
        order to improve the site, perform analysis, or offer support. Our 
        practices conform with the law and the consent provided on behalf of 
        students and children. We may also disclose a user’s personal 
        information if permitted or required by law, for example, in response 
        to a court order or a subpoena.</p>

        <h4>Security</h4>

        <p>The security and confidentiality of your personal information is very 
        important to us. We implement commercially reasonable physical, 
        technical, and administrative security methods to protect the 
        information we collect from users. While the security of your 
        personal information is of the utmost importance to us, we cannot 
        absolutely guarantee the security of any information you choose to 
        disclose.</p>

        <p>We use the secure HTTPS protocol for all page loads. User data is 
        transmitted over a Secure Socket Layer (SSL) connection and encrypted 
        when stored on our servers. Passwords are encrypted in transit using 
        Transport Layer Security (TLS) and at rest using salting and hashing.
        </p>

        <h4>Data Retention</h4>

        <p>{llc} will retain personal information collected online only as long 
        as reasonably necessary to fulfill the educational purpose for which 
        it was collected.</p>

        <h4>Contact Information</h4>

        <p>Questions about this policy or with regards to parental rights 
        provided under COPPA may be sent to:
        {llc}
        mightylearn@gmail.com</p>

        <h4>Disclaimer of Other Sites’ Practices</h4>

        <p>We are not responsible for the privacy practices or the content of 
        other sites.</p>

        <h4>Effective Date</h4>

        <p>This Privacy Policy is effective 01/01/2022. This Privacy Policy may 
        be updated from time to time as necessary.</p>
      </div>
    );
  }
}
