import React from 'react';
import { connect } from 'react-redux';

import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BatchSizeSelector from './BatchSizeSelector.js';
import ListType from './ListType.js';
import ReviewMode from './ReviewMode.js';
import SpellableAccordion from './SpellableAccordion.js';
import SpellableAccordionSummary from './SpellableAccordionSummary.js';
import SpellableButton from './SpellableButton.js';
import SpellableSwitch from './SpellableSwitch.js';
import SubListHandle from './SubListHandle.js';
import SubListMetadata from './SubListMetadata.js';

import { 
  prefetchWords,
} from './actions.js';

const DAILY_REVIEW_PANEL_IDX = 0;
const REGULAR_LIST_PANEL_IDX = 1;
const UNREVIEWED_LIST_PANEL_IDX = 2;
const FREQ_MISS_LIST_PANEL_IDX = 3;

class ListDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onButtonPress = this.onButtonPress.bind(this);
    this.onSwitchToggle = this.onSwitchToggle.bind(this);
    this.onOptionSelect = this.onOptionSelect.bind(this);
    this.onPanelChange = this.onPanelChange.bind(this);

    this.state = { 
      batchSize: 100,
      expandedPanelIndex: 0,
      regularToggle: false,
      unreviewedToggle: false,
      freqMissToggle: false,
    };
  }

  componentDidUpdate(prev_props) {
    const list_id = this.props.listId;
    if (list_id === prev_props.listId) { return; }

    const selected_list = this.props.listsMetadataById[list_id];

    if (selected_list.listType === ListType.FREQUENTLY_MISSED) {
      this.setState({ expandedPanelIndex: FREQ_MISS_LIST_PANEL_IDX });
    } else {
      this.setState({ expandedPanelIndex: DAILY_REVIEW_PANEL_IDX });
    } 

    return;
  }

  getSubListFromList(selected_list, sub_list_handle) {
    return selected_list.subLists.filter(
      x => x['handle'] === sub_list_handle,
    )[0];
  }
    
  onButtonPress(
    selected_list,
    sub_list_handle,
    review_mode,
    start_index,
    button_length,
  ) {
    const selected_value = selected_list.id;

    this.props.prefetchWords(
      this.props.authToken, 
      selected_value, 
      sub_list_handle,
      review_mode,
      start_index,
      button_length,
    );
  }

  onSwitchToggle(sub_list_handle, review_mode) {
    if (sub_list_handle === SubListHandle.STANDARD_SUB_LIST_HANDLE) {
      this.setState({ regularToggle: !this.state.regularToggle });
    } else if (sub_list_handle === SubListHandle.UNREVIEWED_SUB_LIST_HANDLE) {
      this.setState({ unreviewedToggle: !this.state.unreviewedToggle });
    } else if (sub_list_handle === SubListHandle.FREQ_MISS_SUB_LIST_HANDLE) {
      this.setState({ freqMissToggle: !this.state.freqMissToggle });
    }
  }

  onOptionSelect(batch_size) {
    this.setState({ batchSize: batch_size });
  }

  onPanelChange(evt, expanded, panel_index) {
    if (expanded) {
      this.setState({ expandedPanelIndex: panel_index });
    } else {
      this.setState({ expandedPanelIndex: -1 });
    }
  }

  renderButton(
    prefix,
    selected_list,
    sub_list_handle,
    review_mode,
    start_index,
    max_length,
  ) {
    const concept_type = selected_list.conceptType;
    const button_length = (max_length < (this.state.batchSize * 1.5)) ?
      max_length : this.state.batchSize;

    var button_title = "";
    if (concept_type === "SPELLING") {
      button_title = button_length + " Words";
    } else if (concept_type === "VOCAB") {
      button_title = button_length + " Vocab";
    } else if (concept_type === "ROOTS") {
      button_title = button_length + " Roots";
    }

    const onclick_lambda = (
      () => this.onButtonPress(
        selected_list,
        sub_list_handle,
        review_mode,
        start_index,
        button_length,
      )
    );

    return ( 
      <Stack 
        direction="row" 
        alignItems="center" 
        justifyContent="center"
        spacing={2}
        sx={{ paddingBottom: 2 }}>
        <Typography variant="body2">
          {prefix}
        </Typography>
        <SpellableButton 
          variant="outlined"
          size="medium"
          onClick={onclick_lambda}
          disabled={button_length === 0}>
          {button_title}
        </SpellableButton>
      </Stack>
    );
  }

  renderRestartToggle(sub_list_handle, review_mode) {
    const label = "Restart from beginning of list";
    const toggle_lambda = (
      (checked) => { 
        this.onSwitchToggle(sub_list_handle, review_mode, checked)
      }
    );

    return (
      <SpellableSwitch
        defaultChecked={false}
        label={label}
        onChange={toggle_lambda} />
    );
  }

  /* 
    To revert back to a checkbox for the toggle, uncomment this,
    and change the method from onSwitchToggle to onCheckboxToggle.
  
  renderToggle(label, list_mode) {
    var checked_state;

    const toggle_lambda = (
      () => { this.onCheckboxToggle(list_mode) }
    );

    if (list_mode === "TEST_MODE") {
      checked_state = this.state.regularToggle;
    } else if (list_mode === "TEST_UNREVIEWED_MODE") {
      checked_state = this.state.unreviewedToggle;
    } else if (list_mode === "FREQ_MISS_TEST_MODE") {
      checked_state = this.state.freqMissToggle;
    } else {
      console.log("Unexpected value: " + list_mode);
    }

    const checkbox = (
      <Checkbox
        size="medium"
        style={{ color: "black" }}
        checked={checked_state}
        onChange={toggle_lambda} />
    );

    return (
      <Stack alignItems="center">
        <FormControlLabel
         control={checkbox}
         label={<Typography variant="body2">{label}</Typography>}
         size="medium" />
      </Stack>
    );
  } */

  renderDailyReview(selected_list) {
    const sub_list_metadata = new SubListMetadata(
      /* list_name = */ selected_list.name.split("(")[0].trim(),
      /* concept_type = */ selected_list.conceptType,
      /* sub_list_handle = */ SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE,
    );

    const sub_list = this.getSubListFromList(
      selected_list, 
      SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE,
    );

    const remaining = sub_list.length - sub_list.testCompleted;
    const prefix = "Test Mode: ";
    const review_button = this.renderButton(
      prefix, 
      selected_list,
      SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
      /* start_index = */ -1, 
      remaining,
    );

    const panel_lambda = 
      (evt, expanded) => { 
        this.onPanelChange(evt, expanded, DAILY_REVIEW_PANEL_IDX);
      };

    return (
      <SpellableAccordion
        onChange={panel_lambda}
        expanded={this.state.expandedPanelIndex === DAILY_REVIEW_PANEL_IDX}>
        <SpellableAccordionSummary>
          <Typography variant="body1">
            {sub_list_metadata.getSubListTitle(sub_list.length)}&nbsp;
          </Typography>
        </SpellableAccordionSummary>
        <AccordionDetails>
          <Stack 
            alignItems="center" 
            spacing={1}
            sx={{ pt: 1 }} >
            {review_button}
          </Stack>
        </AccordionDetails>
      </SpellableAccordion>
    );
  }
    
  renderRegularList(selected_list) {
    const sub_list_metadata = new SubListMetadata(
      /* list_name = */ selected_list.name.split("(")[0].trim(),
      /* concept_type = */ selected_list.conceptType,
      /* sub_list_handle = */ SubListHandle.STANDARD_SUB_LIST_HANDLE,
    );

    const sub_list = this.getSubListFromList(
      selected_list,
      SubListHandle.STANDARD_SUB_LIST_HANDLE,
    );
    const test_remaining = this.state.regularToggle ?
      sub_list.length : (sub_list.length - sub_list.testCompleted);
    const test_prefix = 
      "Test Mode (" + sub_list.testCompleted + " / " + sub_list.length + ") ";

    const test_button = this.renderButton(
      test_prefix,
      selected_list,
      SubListHandle.STANDARD_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
      /* start_index = */ this.state.regularToggle ? 0 : -1,
      test_remaining,
    );

    const browse_remaining = this.state.regularToggle ?
      sub_list.length : (sub_list.length - sub_list.browseCompleted);
    const browse_prefix = 
      "Browse Mode (" + sub_list.browseCompleted + " / " + sub_list.length + ") ";
    const browse_button = this.renderButton(
      browse_prefix,
      selected_list,
      SubListHandle.STANDARD_SUB_LIST_HANDLE,
      ReviewMode.BROWSE_MODE,
      /* start_index = */ this.state.regularToggle ? 0 : -1,
      browse_remaining,
    );
   
    const panel_lambda = 
      (evt, expanded) => { 
        this.onPanelChange(evt, expanded, REGULAR_LIST_PANEL_IDX);
      };

    const restart_toggle = this.renderRestartToggle(
      SubListHandle.STANDARD_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
    );

    return (
      <SpellableAccordion
        onChange={panel_lambda}
        expanded={this.state.expandedPanelIndex === REGULAR_LIST_PANEL_IDX}>
        <SpellableAccordionSummary
          expandIcon={<ExpandMoreIcon />} >
          <Typography variant="body1">
            {sub_list_metadata.getSubListTitle(sub_list.length)}&nbsp;
          </Typography>
        </SpellableAccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="center" 
            spacing={1}
            sx={{ pt: 1 }} >
            {test_button}
            {browse_button}
            {restart_toggle}
          </Stack>
        </AccordionDetails>
      </SpellableAccordion>
    );
  }
    
  renderUnreviewedList(selected_list) {
    const sub_list_metadata = new SubListMetadata(
      /* list_name = */ selected_list.name.split("(")[0].trim(),
      /* concept_type = */ selected_list.conceptType,
      /* sub_list_handle = */ SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
    );

    const sub_list = this.getSubListFromList(
      selected_list, 
      SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
    );
    const test_remaining = this.state.unreviewedToggle ?
      sub_list.length : (sub_list.length - sub_list.testCompleted);
    const test_prefix = 
      "Test Mode (" + sub_list.testCompleted + " / " + sub_list.length + ") ";
    const test_button = this.renderButton(
      test_prefix,
      selected_list,
      SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
      /* start_index = */ this.state.unreviewedToggle ? 0 : -1,
      test_remaining,
    );

    const browse_remaining = this.state.unreviewedToggle ?
      sub_list.length : (sub_list.length - sub_list.browseCompleted);
    const browse_prefix = 
      "Browse Mode (" + sub_list.browseCompleted + " / " + sub_list.length + ") ";
    const browse_button = this.renderButton(
      browse_prefix,
      selected_list,
      SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
      ReviewMode.BROWSE_MODE,
      /* start_index = */ this.state.unreviewedToggle ? 0 : -1,
      browse_remaining,
    );

    const panel_lambda = 
      (evt, expanded) => { 
        this.onPanelChange(evt, expanded, UNREVIEWED_LIST_PANEL_IDX);
      };

    const restart_toggle = this.renderRestartToggle(
      SubListHandle.UNREVIEWED_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
    );

    return (
      <SpellableAccordion
        onChange={panel_lambda}
        expanded={this.state.expandedPanelIndex === UNREVIEWED_LIST_PANEL_IDX}>
        <SpellableAccordionSummary
          expandIcon={<ExpandMoreIcon />} >
          <Typography variant="body1">
            {sub_list_metadata.getSubListTitle(sub_list.length)}&nbsp;
          </Typography>
        </SpellableAccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="center" 
            spacing={1}
            sx={{ pt: 1 }} >
            {test_button}
            {browse_button}
            {restart_toggle}
          </Stack>
        </AccordionDetails>
      </SpellableAccordion>
    );
  }
    
  renderFrequentlyMissedList(selected_list) {
    const sub_list_metadata = new SubListMetadata(
      /* list_name = */ selected_list.name.split("(")[0].trim(),
      /* concept_type = */ selected_list.conceptType,
      /* sub_list_handle = */ SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
    );

    const sub_list = this.getSubListFromList(
      selected_list,
      SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
    );

    const test_remaining = this.state.freqMissToggle ?
      sub_list.length : (sub_list.length - sub_list.testCompleted);

    const test_prefix = 
      "Test Mode (" + sub_list.testCompleted + " / " + sub_list.length + ") ";
    const test_button = this.renderButton(
      test_prefix, 
      selected_list,
      SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
      /* start_index = */ this.state.freqMissToggle ? 0 : -1,
      test_remaining,
    );

    const browse_remaining = this.state.freqMissToggle ?
      sub_list.length : (sub_list.length - sub_list.browseCompleted);
    const browse_prefix = 
      "Browse Mode (" + sub_list.browseCompleted + " / " + sub_list.length + ") ";
    const browse_button = this.renderButton(
      browse_prefix,
      selected_list,
      SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
      ReviewMode.BROWSE_MODE,
      /* start_index = */ this.state.freqMissToggle ? 0 : -1,
      browse_remaining,
    );
  
    const panel_lambda = 
      (evt, expanded) => { 
        this.onPanelChange(evt, expanded, FREQ_MISS_LIST_PANEL_IDX);
      };
    const restart_toggle = this.renderRestartToggle(
      SubListHandle.FREQ_MISS_SUB_LIST_HANDLE,
      ReviewMode.TEST_MODE,
    );

    return (
      <SpellableAccordion
        onChange={panel_lambda}
        expanded={this.state.expandedPanelIndex === FREQ_MISS_LIST_PANEL_IDX}>
        <SpellableAccordionSummary
          expandIcon={<ExpandMoreIcon />} >
          <Typography variant="body1">
            {sub_list_metadata.getSubListTitle(sub_list.length)}&nbsp;
          </Typography>
        </SpellableAccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="center" 
            spacing={1}
            sx={{ pt: 1 }} >
            {test_button}
            {browse_button}
            {restart_toggle}
          </Stack>
        </AccordionDetails>
      </SpellableAccordion>
    );
  }
    
  render() {
    const list_id = this.props.listId;

    if (list_id === "") {
      return (
        <Stack
          alignItems="center" 
          spacing={1}
          sx={{ pt: 1 }} />
      );
    }

    const selected_list = this.props.listsMetadataById[list_id];

    const sub_list_metadata = new SubListMetadata(
      /* list_name = */ selected_list.name.split("(")[0].trim(),
      /* concept_type = */ selected_list.conceptType,
    );

    const stem = sub_list_metadata.getStem();
    const list_name = sub_list_metadata.getListName();

    var details; 
    if (selected_list.listType === ListType.NON_REVIEW) {
      details = (
        <Container fixed>
          {this.renderDailyReview(selected_list)}
          {this.renderRegularList(selected_list)}
          {this.renderUnreviewedList(selected_list)}
          {this.renderFrequentlyMissedList(selected_list)}
        </Container>
      );
    } else if (selected_list.listType === ListType.DAILY_REVIEW) {
      details = (
        <Container fixed>
          {this.renderDailyReview(selected_list)}
        </Container>
      );
     
    } else if (selected_list.listType === ListType.FREQUENTLY_MISSED) {
      details = (
        <Container fixed>
          {this.renderFrequentlyMissedList(selected_list)}
        </Container>
      );
    }

    return (
      <Stack alignItems="center" spacing={2}>
        <Card elevation={0}>
          <CardContent>
            <Stack alignItems="center" spacing={1}>
              <Typography 
                variant="body1"
                sx={{ fontWeight: "bold" }}>
                {list_name}
              </Typography>
              <Typography variant="body2">
                {"List Length: " + selected_list.listLength + " " + stem}
              </Typography>
              <Typography variant="body2">
                {"Reviewed: " + selected_list.reviewed + " " + stem}
              </Typography>
              <Box sx={{pt: 3}}>
                <BatchSizeSelector onOptionSelect={this.onOptionSelect} />
              </Box>
            </Stack>
          </CardContent>
        </Card>
        {details}
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: state.loginState.authToken,
    listsMetadataById: state.prefetchState.listsMetadataById,
    subListMetadata: state.selectedListState.subListMetadata,
  };
}

const mapDispatchToProps = {
  prefetchWords,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDetails);

