import React from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';

class WordRoot extends React.Component {
  render() {
    const root = (this.props.updatedRoot !== undefined) ?
      this.props.updatedRoot : this.props.root;

    return (
      <Typography 
        variant="body1"
        sx={{ paddingTop: 2, fontWeight: 'bold' }}>
        Root: {root}
      </Typography>
    );
  }
}

function mapStateToProps(state) {
  const list_state = state.selectedListState;
  const word_data = state.conceptState.getWordData();
  const spelling = word_data.spelling;

  return {
    root: word_data.root,
    updatedRoot: list_state.updatedRoots[spelling],
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WordRoot);
