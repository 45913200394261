import React from 'react';
import { connect } from 'react-redux';
import { updateAnswer, submitAnswer } from './actions.js';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import SpellableInput from './SpellableInput.js';

class WordInput extends React.Component {
  constructor(props) {
    super(props);
    this.onNewInput = this.onNewInput.bind(this);
    this.onAnswer = this.onAnswer.bind(this);
  }

  onNewInput(answer_so_far) {
    this.props.updateAnswer(answer_so_far, /* chosen_option = */ null);
  }

  onAnswer(entered_answer) {
    /*
    NO LONGER NECESSARY SINCE MULTIPLE CHOICE CARDS DON'T HAVE THIS ELEMENT

    if (this.props.options.length > 0) {
      const choice_string = 
        entered_answer.replace(/[?]/g, '').trim().toUpperCase();

      if (choice_string.length > 1) { return; }
      const choice_id = choice_string.charCodeAt(0) - "A".charCodeAt(0);

      if ((choice_id < 0) || (choice_id >= this.props.options.length)) {
        return;
      }
    }*/

    this.props.submitAnswer();
  }

  render() {
    var img_link, alt;

    if (this.props.answerIsCorrect === true) {
      img_link = "https://s3.amazonaws.com/thousandwords/check.png";
      alt = "Correct";
    } else if (this.props.answerIsCorrect === false) {
      img_link = "https://s3.amazonaws.com/thousandwords/cross.png";
      alt = "Incorrect";
    } else {
      img_link = "";
      alt = "";
    }

    return (
      <Stack direction="row" alignItems="center">
        <SpellableInput
          onChange={this.onNewInput}
          onSubmit={this.onAnswer}
          value={this.props.enteredAnswer}
          disabled={this.props.answerIsLocked} />

         <Box 
           component="img"
           src={img_link}
           alt={alt}
           sx={{ height: 20, width: 20, ml: 1  }}
           hidden={!this.props.answerIsLocked} />
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  updateAnswer,
  submitAnswer,
}

export default connect(mapStateToProps, mapDispatchToProps)(WordInput);
