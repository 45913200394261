export default class Duration {
  constructor(duration_in_ms) {
    this.duration_in_millisecs = duration_in_ms;
  }

  readableString() {
    const duration_in_ms = this.duration_in_millisecs;
    const duration_in_secs = Math.floor(duration_in_ms / 1000);

    const seconds = duration_in_secs % 60;
    if (duration_in_secs < 60) {
      return seconds + "s";
    }

    const minutes = Math.floor(duration_in_secs / 60) % 60;
    if (duration_in_secs < 3600) {
      return minutes + "min " + seconds + "s";
    }

    const hours = Math.floor(duration_in_secs / 3600);
    return hours + "h " + minutes + "min " + seconds + "s";
  }
}
