import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { 
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';

import Main from './Main.js';
import FAQ from './FAQ.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import SignUp from './SignUp.js';
import TermsOfUse from './TermsOfUse.js';

import './index.css';


class SpellableRoot extends React.Component {
  render() {
    // const prefers_dark_mode = sessionStorage.getItem('dark_mode');
    const prefers_dark_mode = this.props.darkMode;

    const theme = createTheme({
      palette: {
        mode: prefers_dark_mode === true ? 'dark' : 'light',
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<Main />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfUse />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    darkMode: state.darkModeState,
  };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SpellableRoot);

