import React from 'react';
import { connect } from 'react-redux';

import CachedIcon from '@mui/icons-material/Cached';

import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AudioButton from './AudioButton.js';

import {
  setAudioState,
} from './actions.js';

class AudioControl extends React.Component {
  constructor(props) {
    super(props);
    this.changeVoice = this.changeVoice.bind(this);
    this.afterAudioPlay = this.afterAudioPlay.bind(this);
  }

  changeVoice() {
    this.props.setAudioState(
      /* auto_play = */ true,
      /* voice_index = */ this.props.voiceIndex === 1 ? 2 : 1,
    );
  }

  afterAudioPlay() {
    this.props.setAudioState(
      /* auto_play = */ false,
      /* voice_index = */ null,
    );
  }

  renderAudioButton(file_key, description, is_hidden) {
    return (
      <AudioButton 
        key={file_key}
        fileKey={file_key} 
        text={description} 
        isHidden={is_hidden}
        autoPlay={this.props.autoPlay}
        afterAudioPlay={this.afterAudioPlay}
      />
    );
  }

  renderChangeButton() {
    return (
      <Stack 
        alignItems="center" 
        sx={{ paddingTop: 1, paddingLeft: 6 }}
        spacing={1}>
        <Button
          sx={{ 
            color: 'text.primary', 
            border: 2, 
            borderColor: "text.disabled" 
          }}
          onClick={this.changeVoice}>
          <CachedIcon sx={{ fontSize: "40px" }} />
        </Button>
        <Typography variant="subtitle2">
          Change Pronouncer
        </Typography>
      </Stack>
    );
  }

  render() {
    const voice_index = this.props.voiceIndex;

    var audio_buttons = [
      this.renderAudioButton(
        this.props.uniqueKey + "_1", 
        "Pronouncer 1", 
        (voice_index !== 1),
      ),
      this.renderAudioButton(
        this.props.uniqueKey + "_2", 
        "Pronouncer 2", 
        (voice_index !== 2),
      ),
    ];

    return (
      <Stack 
        direction="row" 
        justifyContent="center"
        alignItems="center">
        {audio_buttons}
        {this.renderChangeButton()}
      </Stack>
    );
  }
}    

function mapStateToProps(state) {
  return {
    autoPlay: state.currentCardState.audioState.autoPlay,
    voiceIndex: state.currentCardState.audioState.voiceIndex,
  };
}

const mapDispatchToProps = {
  setAudioState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioControl);

