import React from 'react';
import { connect } from 'react-redux';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

const SMALL_BATCH_SIZE = "25"
const MEDIUM_BATCH_SIZE = "100"
const LARGE_BATCH_SIZE = "500"

class BatchSizeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.selectActiveButton = this.selectActiveButton.bind(this);
    // Pick the middle one by default
    this.state = { batchSizeString: MEDIUM_BATCH_SIZE };
  }

  selectActiveButton(e, str_val) {
    if (str_val === this.state.batchSizeString) {
      e.preventDefault();
      return;
    }

    if ((str_val !== SMALL_BATCH_SIZE) && 
        (str_val !== MEDIUM_BATCH_SIZE) && 
        (str_val !== LARGE_BATCH_SIZE)) {
      e.preventDefault();
      return;
    }

    this.setState({ batchSizeString: str_val });
    const batch_size = parseInt(str_val, 10);

    this.props.onOptionSelect(batch_size);
  }

  renderToggleButton(batch_size_str, label) {
    return(
      <ToggleButton 
        value={batch_size_str}
        sx= {{ textTransform: "none" }}
        aria-label={label}>
        <Typography variant="body2">
          {label}
        </Typography>
      </ToggleButton>
    );
  }

  render() {
    return (
      <Stack 
        direction="row" 
        alignItems="center"
        spacing={1}>

        <Typography variant="body2">
          Batch Size:
        </Typography>

        <ToggleButtonGroup 
          exclusive
          size="small"
          value={this.state.batchSizeString}
          onChange={this.selectActiveButton}>

          {this.renderToggleButton(SMALL_BATCH_SIZE, "Small")}
          {this.renderToggleButton(MEDIUM_BATCH_SIZE, "Medium")}
          {this.renderToggleButton(LARGE_BATCH_SIZE, "Large")}
        </ToggleButtonGroup>
      </Stack>
    );
  }
} 

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BatchSizeSelector);

