import React from 'react';
import { connect } from 'react-redux';

class CollapsibleCard extends React.Component {
  constructor(props) {
    super(props);
    this.toggleCardState = this.toggleCardState.bind(this);
    this.state = { open: true };
  }

  toggleCardState() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const img_link = "https://s3.amazonaws.com/thousandwords/rightarrow.png";
    var content = null;
    var class_name = "collapsible-title";
    var pointer_class_name = "arrow-right";

    if (this.state.open === true) {
      content = (
        <div>{this.props.children}</div>
      );

      pointer_class_name = "arrow-down";
    }
    
    return (
      <div className="collapsible">
        <div
          role="button"
          className={class_name}
          onClick={this.toggleCardState}>
          {this.props.title}
          <img className={pointer_class_name} src={img_link} alt="" />
        </div>
        <div>
          {content}
        </div>
      </div>
    );
  }
} 

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleCard);

