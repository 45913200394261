import React from 'react';

import Constants from './Constants.js';

export default class TermsOfUse extends React.Component {
  render() {
    const llc = Constants.llc;
    const LLC = llc.toUpperCase();
  

    return (
      <div>
        <h1>Terms of Use</h1>

        <p>Welcome to the {llc}'s website. These Terms of Use govern your use of 
        www.mightywords.co, and any successor websites of the foregoing 
        (collectively, the "Site"), and all of the services made available on 
        the Site (the "Services"). By using the Services or visiting or 
        browsing the Site, you acknowledge that you have read, understood, 
        and agreed to be bound by these Terms of Use and any modifications 
        that may be made to these Terms of Use from time to time. In 
        addition, to the extent our Services require you to set up an account 
        with a password, if you are designated as the parent or legal 
        guardian in the account, or are otherwise the person who first 
        registers for a Service in such account, you agree to be responsible 
        for ensuring that all users on your account comply with these Terms 
        of Use and any modifications that may be made to the Terms of Use 
        from time to time. If you do not agree to these Terms of Use, you 
        should not use the Services, or visit or browse the Site.</p>

        <p>These Terms of Use constitute a binding legal agreement between you 
        and {llc} ("Mighty Learning," "Mighty Words," "we," "us," and "our"). 
        If you are using the Site or Services, on behalf of any entity or 
        person (including child), you represent and warrant that you are 
        authorized to accept these Terms of Use on such entity's behalf or 
        such person's behalf, and that such entity or person agrees to 
        indemnify you and {llc} for violations of these Terms of Use. Please 
        read these Terms of Use carefully before accessing or using the Site 
        or the Services.</p>

        <p>We reserve the right to modify these Terms of Use at any time and in 
        any manner at our sole discretion, including the fees for the Site or 
        Services. Notice of any material modification of these Terms of Use 
        will be posted in this section of the Site, and any such 
        modifications will be effective upon the posting of such notice. Your 
        continued use of the Site or the Services constitutes your binding 
        acceptance of such modifications. Please check this section of the 
        Site before using the Site or the Services to determine whether a 
        change has been made to these Terms of Use. If you do not agree to 
        any changes in the Terms of Use as they may occur, please arrange to 
        terminate your registration with the Site immediately and discontinue 
        your use of the Service and the Site. You agree that we are not 
        liable to you or to any third party for any modification of the Terms 
        of Use.</p>

        <h4>Registration:</h4>

        <p>Registration is required for use of certain Services offered on the 
        Site. When registration is required, the User agrees to provide all 
        required information accurately. {llc} reserves the right to suspend 
        or terminate Services to any User, if {llc} in its sole discretion 
        determines that the registration information is not accurate and 
        complete.</p>

        <p>If you are a customer who is an individual, you agree that, if you 
        provide any personally identifiable information about a child under 
        age 13 in order to allow them to use the Site or Services, that you 
        are the parent/legal guardian of such child and that you consent to 
        the child's use of the Site and Service and agree to be bound to 
        these Terms of Use with respect to the child's use. You understand 
        that the privacy policy will apply to the child's use of the Site and 
        Service.</p>

        <p>You are responsible for maintaining the confidentiality of your 
        account and password and for restricting access to your computer. You 
        are solely responsible for any activity related to your account. If 
        you suspect any unauthorized use of your account, notify us 
        immediately. You acknowledge and agree that we may preserve user 
        information and may also disclose user information, if required to do 
        so by law or if we believe, in good faith, that such preservation or 
        disclosure is reasonably necessary to: (a) comply with legal process; 
        (b) enforce these Terms of Use; or (c) protect the rights, property, 
        or personal safety of {llc}, its users, or the public. Without 
        limiting the foregoing, parents and legal guardians who have 
        registered accounts hereunder, understand that they are responsible 
        for the acts and activities of their minor children in connection 
        with any use of the Site and/or Software, and that the privacy policy 
        will apply to their family's use of the foregoing.</p>

        <h4>Acceptable Use of Services</h4>

        <p>The User agrees to the following when using the Site or Services:</p>

        <ul>
        <li>Not to impersonate any person or entity, including, but not limited 
        to, an employee of {llc} or another User, or falsely state or 
        otherwise misrepresent your affiliation with any person or entity;</li>
        <li>Not to copy, modify, decompile, disassemble, translate into another 
        computer language, create derivative works, access the source code, 
        hack, decrypt, rename files, or otherwise reverse engineer {llc}'s 
        websites or intellectual property;</li>
        <li>The User agrees not to reproduce, duplicate, copy, sell, resell or 
        exploit for any commercial purposes, any portion of the Services, use 
        of the Services, or access to the Services.</li>
        <li>Not to permit use of the Services by anyone other than the User or 
        other authorized Users;</li>
        <li>Not to upload, post, e-mail, or otherwise transmit any material 
        that contains software viruses or any other computer code, files, or 
        programs designed to interrupt, destroy, or limit the functionality 
        of any computer software or hardware or telecommunications equipment;</li>
        <li>Not to interfere with or disrupt the Services, Software, the Site, 
        or servers or networks connected to the Site, or disobey any 
        requirements, procedures, policies, or regulations of networks 
        connected to the Site;</li>
        <li>Not to use any automated means to access the Site, Software or the 
        Services or collect any information from the Site, Software or the 
        Services (including, without limitation, robots, spiders, or 
        scripts); or</li>
        <li>Not to frame the Site or the Services, utilize framing techniques 
        to enclose any service mark, logo, or other proprietary information, 
        place pop-up windows over its pages, or otherwise affect the display 
        of its pages. This means, among other activities, that you must not 
        engage in the practices of "screen scraping," "database scraping," or 
        any other activity with the purpose of obtaining lists of users or 
        other information.</li>
        <li>Not to intentionally or unintentionally violate any local, state, 
        national, or international law (including applicable export or 
        re-export control laws and regulations);</li>
        <li>Not to collect or store personal data about other users, and</li>
        <li>Not to facilitate, encourage, or otherwise support any User's acts 
        that violate the terms of this Agreement.</li>
        </ul>

        <p>{llc} reserves the right to suspend or terminate Services to any 
        User, if {llc}, in its sole discretion, determines that a User does 
        not comply with these terms.</p>

        <h4>Fee-based Services</h4>

        <p>Some Services may be offered to you on a fee basis. All fees are 
        quoted and must be paid in U.S. Dollars. If you elect to purchase 
        fee-based Services and transmit to {llc} a purchase request, you 
        warrant that your use of the particular credit card or other accepted 
        payment method is authorized and that all information that you submit 
        to {llc}, or any third party designated by {llc}, is true and 
        accurate (including, without limitation, your credit card number and 
        expiration date), and you agree to pay all fees, including any 
        applicable sales taxes, you incur. Any account name, password, or 
        user ID supplied to you in connection with any fee-based Services you 
        purchase is personal to you and the members of your household, and 
        you may not transfer or make available your account name, password, 
        or user ID to others. Any distribution by you of such account name, 
        password, and/or user ID may result in cancellation of the fee-based 
        Services without refund and the imposition of additional charges 
        based on your unauthorized use.</p>

        <h4>Termination</h4>

        <p>You agree that {llc} in its sole discretion, may terminate your 
        password, account (or any part thereof), and use of the Site and the 
        Services, for any reason, including, without limitation, for lack of 
        use or if {llc} believes that you have violated or acted 
        inconsistently with the letter or spirit of these Terms of Use. {llc} 
        may also in its sole discretion and at any time modify or discontinue 
        providing the Site or the Services, or any part thereof, with or 
        without notice. You agree that any modification or termination of 
        your access to the Site or the Services may be effected without prior 
        notice, and you acknowledge and agree that {llc} may immediately 
        deactivate or delete your account and all related information and 
        Content in your account and bar any further access to such 
        information or to the Site or the Services. Further, you agree that 
        {llc} shall not be liable to you or any third party for any 
        modification or termination of your access to the Site or the 
        Services.</p>

        <p>The User may close his/her account and cease use of the Services at 
        any time.</p>

        <h4>Warranties</h4>

        <p>{llc} makes no warranty that: (a) the Services will meet your 
        requirements, (b) the Services will be uninterrupted, timely, secure, 
        or error-free, (c) the results that may be obtained from the use of 
        the Services will be accurate or reliable, (d) the quality of any 
        products, Services, information, or other material purchased or 
        obtained by you through the Services will meet your expectations, and 
        (e) any errors in the software will be corrected.</p>

        <p>{LLC} MAKES NO WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, 
        ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. 
        {LLC} SHALL NOT HAVE ANY LIABILITY WHATSOEVER FOR ANY COVER OR SET 
        OFF NOR FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, OR 
        PUNITIVE DAMAGES, INCLUDING LOST PROFITS, EVEN IF SUCH PARTY HAS BEEN 
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

        <p>{llc} assumes no responsibility for errors or omissions in these 
        materials. {llc} makes no commitment to update the information 
        contained herein.</p>

        <h4>Indemnification:</h4>

        <p>The User agrees to indemnify, hold harmless and defend {llc} against 
        any and all third-party claims (including all associated legal fees 
        and disbursements actually incurred) against {llc} arising out of a 
        breach by the User of the obligations under this Agreement, or the 
        use or misuse of the {llc}'s Site or Services by User.</p>

        <h4>Risk and Limitation of Liability:</h4>

        <p>{llc} shall not be liable for any monetary damages with respect to 
        the User's use of the Services hereunder, nor shall {llc} be liable 
        for any indirect, incidental, consequential, special, punitive or 
        exemplary damages arising out of this Agreement. The total liability 
        of {llc}, whether under the express or implied terms of this 
        Agreement, in tort (including negligence or other duty of care) or at 
        common law, for any loss or damage including but not limited to any 
        data loss or corruption, suffered by User or any Users authorized the 
        User, whether direct, indirect or special, or any other similar 
        damage that may arise or does arise from use of the {llc}'s website 
        or Services or any breach of this Agreement by {llc}, shall in no 
        event exceed the amount paid by the User to {llc} for the Services. 
        These limitations of liability shall survive failure of any exclusive 
        remedies provided in the Agreement.</p>

        <h4>Assignment:</h4>

        <p>The User will not assign, transfer, delegate any or all of its rights 
        or its duties or obligations under the Agreement without the consent 
        of {llc}. {llc} may assign the Agreement to another third-party.</p>

        <h4>No Construction Against Drafter:</h4>

        <p>If an ambiguity or question of intent arises with respect to any 
        provision of this Agreement, the Agreement will be construed as if 
        drafted jointly by the parties and no presumption or burden of proof 
        will arise favoring or disfavoring either party by virtue of 
        authorship of any of the provisions of these terms.</p>

        <h4>Force Majeure:</h4>

        <p>Either party shall be excused from performance under the Agreement 
        and shall not be liable for any delay in whole or in part, caused by 
        the occurrence of any contingency beyond the reasonable control 
        either of the excused party or its subcontractors or suppliers 
        including, but not limited to, war, sabotage, insurrection, riot or 
        other act of civil disobedience, act of public enemy, failure or 
        delay in transportation, act of any government or any agency or 
        subdivision thereof affecting the terms hereof, accident, fire, 
        explosion, flood, severe weather or other act of God, or shortage of 
        labor or fuel or raw materials.</p>

        <h4>Limits for Data Storage and Use:</h4>

        <p>{llc} may establish general practices and limits concerning use of 
        the Services, including the maximum disk space that will be allotted 
        on our servers on a User's behalf, and the maximum number of times a 
        User may access the Services in a given period of time. {llc} has no 
        responsibility or liability for the deletion or failure to store any 
        data or other Content maintained or transmitted by the Service. {llc} 
        reserves the right to terminate accounts that are inactive for an 
        extended period of time.</p>

        <h4>No Agency:</h4>

        <p>No agency, partnership, joint venture, or employment is created as a 
        result of this Agreement and you do not have any authority to bind 
        {llc} in any respect whatsoever.</p>
      </div>
    );
  }
}
