import React from 'react';

export default class FAQ extends React.Component {
  render() {
    return (
      <div>
        <h1>Instructions & FAQ</h1>

        <p>Welcome to Mighty Words! This service is in beta (which means it is 
        actively being improved and augmented with new features). This 
        service currently allows you to master all the words (both spelling 
        and vocabulary) for the 450 list and many of 
        the 4000 Words of Champions which are a part of 
        the Scripps National Spelling Bee.</p>

        <p>We welcome your feedback. Please send your questions and feedback to 
        mightylearn@gmail.com.</p>

        <h2>Using this software</h2>

        <p>This software uses a system called spaced repetition to maximize the 
        rate at which you learn words. The student picks a specified set of 
        words to review on any given day. The software keeps track of which 
        words the student got right and which ones they got wrong. The 
        software then calculates after how many days should the student be 
        quizzed on the same word, so that the student will remember it best 
        going forward.</p>

        <p>This way, the student no longer has to worry about going back and 
        reviewing words that have already been reviewed. The software takes 
        care of quizzing the student on the word after the right number of 
        days to maximize the chance of the student remembering the word.</p>

        <ol>
        <li>Pick a list to review. We recommend starting with the School Bee 
        450 list and then working your way through the other lists.</li>
        <li>Decide whether to review 25 or 100 words - the list will pick that 
        many random words for you to review.</li>
        <li>You must finish the entire list for the service to register the 
        list as done and for the service to register your progress.</li>
        <li>Once done, continue picking another set of words until you are 
        done for the day (or done with the list).</li>
        <li>Every day after, look out for what the service offers for daily 
        review. Aim to finish all your daily review each day. If you are not 
        able to, they will simply be there for you the next day.</li>
        </ol>

        <p>Please send any feedback (or if you encounter any issues) to 
        mightylearn@gmail.com.</p>

        <p>Good luck learning!</p>
      </div>
    );
  }
}
