import React from 'react';
import Button from '@mui/material/Button';

import { connect } from 'react-redux';

class SpellableButton extends React.Component {
  render() {
    const {forwardedRef, ...rest} = this.props;

    return (
      <Button 
        ref={forwardedRef}
        {...rest}
        sx={{
          ...rest.sx,
          textTransform: "none", 
          color: "text.primary",
          borderColor: "text.disabled",
          ':hover': { borderColor: "text.primary" },
        }}>
        {this.props.children}
      </Button>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps, 
  mapDispatchToProps,
  null,
  {forwardRef: true},
)(React.forwardRef(
  (props, ref) => {
    return <SpellableButton {...props} forwardedRef={ref} />;
  }
))
