import React from 'react';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';

class SpellableInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.textInput = React.createRef();
  }

  componentDidMount() {
    if (!this.props.disabled && !this.props.hidden) {
      this.textInput.current.focus();
    }
  }

  componentDidUpdate(prev_props) {
    if (!this.props.disabled && !this.props.hidden) {
      this.textInput.current.focus();
    }
  }

  handleChange(e) {
    this.props.onChange(e.target.value);
  }

  keyPress(e) {
    if (e.keyCode === 13) {
      const input_str = e.target.value.trim();
      // Ignore the enter key if the entered string is empty,
      // it is usually an error when users are typing fast.
      if (input_str === "") { return; }
      this.props.onSubmit(input_str);
    }
  }

  render() {
    const label = (this.props.label ? this.props.label : "");
    return (
      <TextField
        type="text"
        sx={{ width: 300, p: 1 }}
        autoComplete="off"
        inputProps={{
          spellCheck: "false",
          autoComplete: "off",
          autoCorrect: "off",
          autoCapitalize: "none",
        }}
        size="small"
        label={label}
        inputRef={this.textInput}
        value={this.props.value}
        onChange={this.handleChange}
        onKeyDown={this.keyPress}
        disabled={this.props.disabled}
        fullWidth={true} />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SpellableInput);
 
