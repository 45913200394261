import React from 'react';
import { connect } from 'react-redux';

class MultipleChoiceDetails extends React.Component {
  render() {
    var text_option = 
      "Option (" + 
      String.fromCharCode("A".charCodeAt(0) + this.props.chosenOption) +
      ")"

    var text;
    var image_url, image_elem, alt;

    if (!this.props.answerGiven) {
      if (this.props.chosenOption === -1) {
        text = "No Option selected.";
      } else {
        text = "Selected " + text_option + ".";
      }

      image_url = null;
      image_elem = null;
      alt = null;
    } else {
      if (this.props.answerIsCorrect) {
        text = text_option + " is correct.";
        image_url = "https://s3.amazonaws.com/thousandwords/check.png";
        alt = "Correct";
      } else {
        text = text_option + " is incorrect.";
        image_url = "https://s3.amazonaws.com/thousandwords/cross.png";
        alt = "Incorrect";
      }

      image_elem = (
        <img className="answer-img" src={image_url} alt={alt} />
      );
    }
      
    return (
      <div className="multiple-choice-details">
        {image_elem}
        <span className="multiple-choice-text">{text}</span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    answerGiven: state.currentCardState.answerState.answerGiven,
    answerIsCorrect: state.currentCardState.answerState.answerIsCorrect,
    chosenOption: state.currentCardState.answerState.chosenOption,
  };
}

export default connect(mapStateToProps)(MultipleChoiceDetails);

