import React from 'react';

import { createRoot } from 'react-dom/client';

import './index.css';

import ConceptCollection from './ConceptCollection.js';
import Constants from './Constants.js';
import RandomQueue from './RandomQueue.js';
import SpellableRoot from './SpellableRoot.js';
import SubListMetadata from './SubListMetadata.js';
import registerServiceWorker from './registerServiceWorker';

import { 
  UPDATE_DARK_MODE,
  UPDATE_EMAIL,
  PRE_LOGIN_BEGIN,
  PRE_LOGIN_SUCCESS,
  PRE_LOGIN_FAILURE,
  UPDATE_PASSPHRASE,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FAST_LOGIN,
  PREFETCH_LANDING_STATE_BEGIN,
  PREFETCH_LANDING_STATE_FAILURE,
  PREFETCH_LANDING_STATE_SUCCESS,
  PREFETCH_WORDS_BEGIN,
  PREFETCH_WORDS_SUCCESS,
  PREFETCH_WORDS_FAILURE,
  PREFETCH_WORDS_RESET,
  LOG_REVIEWED_WORDS_BEGIN,
  LOG_REVIEWED_WORDS_SUCCESS,
  LOG_REVIEWED_WORDS_FAILURE,
  SET_AUDIO_STATE,
  PROBLEM_REPORTED,
  UPDATE_ROOT,
  SUBMIT_ROOT,
  UPDATE_ANSWER,
  SUBMIT_ANSWER,
  NEXT_WORD_REQUESTED, 
} from './actions.js';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

export const OperationState = {
  IN_PROGRESS: "in_progress",
  SUCCESS: "success",
  FAILURE: "failure",
};

// TODO: Check if unused, then remove.
export const ReviewType = {
  DEFAULT: "default",
  INFREQ_REVIEW: "infrequently_reviewed",
  REPEATED_MISS: "repeatedly_missed",
};

export const ConceptType = {
  SPELLING: 1,
  VOCAB: 2,
  ROOTS: 3,
};

const initial_state = {
  darkModeState: false,
  loginState: {
    authToken: null,
    email: "",
    passphrase: "",
    emailSubmitted: false,
    emailProcessed: false,
    passwordSubmitted: false,
  },
  prefetchState: {
    prefetchStatus: null,
    listsMetadataById: null,
    listsByConceptType: null,
    summariesByConceptType: null,
  },
  selectedListState: {
    fetchStatus: null,
    listId: null,
    subListMetadata: null,
    conceptCollection: null,
    listComplete: null,
  },
  conceptState: null,
  currentCardState: {
    audioState: {},
    answerState: {},
    problemState: {},
  },
}

function initializeSelectedListState(
  selected_list,
  sub_list_handle,
  review_mode,
  words,
) {
  var ii;
  var voice_indices = {};

  for (ii = 0; ii < words.length; ii++) {
    // Roots never have audio; skip them.
    if (selected_list.conceptType === 'ROOTS' && 
        words[ii].chainedId !== null) {
      continue;
    }

    voice_indices[words[ii].uniqueKey] = [ words[ii].voiceIndex ];
    if (words[ii].voiceIndex === 0) {
      var new_voice_index = Math.floor(Math.random() * 2);
      // Make it start at 1.
      new_voice_index += 1;

      words[ii].voiceIndex = new_voice_index;
      // Weird historical thing - dict-value has to be an array.
      voice_indices[words[ii].uniqueKey] = [ new_voice_index ];
    }
  }

  const concept_collection = new ConceptCollection(
    /* words = */ words,
    /* concept_type = */ selected_list.conceptType,
    /* review_mode = */ review_mode,
  );

  const queue_current = new RandomQueue(words.length);
  const queue_next = new RandomQueue(words.length);

  for (ii = 0; ii < concept_collection.getLength(); ii++) {
    queue_current.enqueue(concept_collection.getConceptAtIndex(ii));
  }

  const sub_list_metadata = new SubListMetadata(
    /* list_name = */ selected_list.name.split("(")[0].trim(),
    /* concept_type = */ selected_list.conceptType,
    /* sub_list_handle = */ sub_list_handle,
    /* review_mode = */ review_mode,
    /* list_size = */ concept_collection.getLength(),
  );
    
  
  return {
    fetchStatus: OperationState.SUCCESS,
    listId: selected_list.id,
    subListMetadata: sub_list_metadata,
    conceptCollection: concept_collection,
    listComplete: false,
    queueCurrent: queue_current,
    queueNext: queue_next,
    uploadStatus: null,
    correctAnswers: 0,
    totalAnswers: 0,
    attemptedCount: {},
    masteredWords: {},
    unsureWords: {},
    incorrectlyAnsweredWords: {},
    updatedRoots: {},
    startTimeInMillisecs: Date.now(),
    problemReports: {},
    voiceIndices: voice_indices,
  };
}

function initializeCurrentCardState(concept_state, multiple_choice_options) {
  return {
    audioState: {
      autoPlay: true,
      voiceIndex: concept_state.getWordData().voiceIndex,
    },
    answerState: {
      answerGiven: false,
      isConfident: true,
      enteredAnswer: "",
      chosenOption: -1,
      // Cannot default to false, because that results in a transient
      // rendering of the x icon before switching to check (when
      // word is answered correctly.
      answerIsCorrect: null,
    },
    enteredRoot: "",

    // defaults to [] for spelling lists.
    multipleChoiceOptions: multiple_choice_options,
    problemState: {
      audio: false, 
      root: false, 
      meanings: false, 
      notes: false,
    },
  };
}

const MIN_MATCH_LEN = 5;

function isHint(spelling, word) {
  word = word.toLowerCase();
  spelling = spelling.toLowerCase();

  if (word.length < MIN_MATCH_LEN) return false;
  if (spelling.length < MIN_MATCH_LEN) return false;

  for (var ii = 0; ii < spelling.length - MIN_MATCH_LEN; ii++) {
    if (word.includes(spelling.substring(ii, ii + MIN_MATCH_LEN))) {
      return true;
    }
  }

  return false;
}

function obfuscateHints(word_objs) {
  for (var ii = 0; ii < word_objs.length; ii++) {
    const cur_word = word_objs[ii]
    const spelling = cur_word.spelling;

    cur_word.obfuscatedMeanings = Array(cur_word.meanings.length).fill("");

    for (var jj = 0; jj < cur_word.meanings.length; jj++) {
      var meaning = cur_word.meanings[jj];
      const words = meaning.split(" ");

      for (var kk = 0; kk < words.length; kk++) {
        const word = words[kk];

        if (isHint(spelling, word)) {
          meaning = meaning.replace(word, "*******");
        }
      }

      cur_word.obfuscatedMeanings[jj] = meaning;
    }
  }

  return word_objs;
}

const languageAbbreviations = {
  "AF": "Anglo-French",
  "Alb": "Albanian",
  "Arm": "Armenian",
  "Av": "Avestan",
  "D": "Dutch",
  "Dan": "Danish",
  "E": "English",
  "Ecf": "English combining form",
  "Eponym": "Eponym ",
  "F": "French",
  "Fcf": "French combining form",
  "Fr": "French",
  "Geog": "Geographical name ",
  "Ger": "German",
  "Gk": "Greek",
  "Gmc": "Germanic",
  "Goth": "Gothic",
  "Heb": "Hebrew",
  "Hitt": "Hittite",
  "IrGael": "Irish Gaelic",
  "ISV": "ISV",
  "It": "Italian",
  "L": "Latin",
  "Lcf": "Latin combining form",
  "Lith": "Lithuanian",
  "LL": "Late Latin",
  "MD": "Middle Dutch",
  "ME": "Middle English",
  "MF": "Middle French",
  "MFr": "Middle French",
  "ML": "Middle Latin",
  "NL": "New Latin",
  "OE": "Old English",
  "OF": "Old French",
  "OFr": "Old French",
  "ON": "Old Norse",
  "Pers": "Persian",
  "Port": "Portugese",
  "Russ": "Russian",
  "Scand": "Scandinavian",
  "Sp": "Spanish",
  "Sw": "Swedish",
  "Turk": "Turkish",
  "VL": "Vulgar Latin",
  "W": "Welsh",
};


function transformRoot(root_str) {
  root_str = root_str.trim();

  // Algorithm:
  // 1. Spaces are skipped.
  // 2. + is printed with a space on either side.
  // 3. > is printed as a different character, with space on either side.
  // 4. Words composed of alphabetical characters are potentially transformed.

  var result = "";
  var text_buffer = "";
  for (var ii = 0; ii < root_str.length; ii++) {
    var cur_char = root_str.charAt(ii);

    // console.log("Current Char: " + cur_char);

    if ("[]+>?&".indexOf(cur_char) !== -1) {
      text_buffer = text_buffer.trim();
      if (text_buffer !== "") { 
        // console.log(text_buffer);
        result += (text_buffer in languageAbbreviations) ? 
          languageAbbreviations[text_buffer] : 
          text_buffer;
        // console.log(result);
      }

      text_buffer = "";
    }
      
    if ("[]?".indexOf(cur_char) !== -1) {
      result = result + cur_char;
      continue;
    }

    if ("+&".indexOf(cur_char) !== -1) {
      result = result + " " + cur_char + " ";
      continue;
    }

    if (cur_char === ">") {
      result = result + " ➜ ";
      continue;
    }

    // Anything else gets accumulated in the text buffer.
    text_buffer = text_buffer + cur_char;
  }

  text_buffer = text_buffer.trim(); 
  // console.log(text_buffer);
  if (text_buffer !== "") { 
    // console.log(result);
    result += (text_buffer in languageAbbreviations) ? 
      languageAbbreviations[text_buffer] : 
      text_buffer;
    // console.log(result);
  }

  text_buffer = "";
  // console.log("FInal Result = " + result);
  return result;
}

function reducer(state = initial_state, action) {
  var concept_state;

  if (action.type === UPDATE_DARK_MODE) {
    return {
      ...state,
      darkModeState: action.darkMode,
    };
  }

  if (action.type === UPDATE_EMAIL) {
    return {
      ...state,
      loginState: {
        ...state.loginState,
        email: action.email,
        // authToken, emailSubmitted, emailProcessed and passwordSubmitted
        // should all have default values at this point.
        authToken: null,
        emailSubmitted: false,
        emailProcessed: false,
        passwordSubmitted: false,
      },
    };
  }

  if (action.type === PRE_LOGIN_BEGIN) {
    return {
      ...state,
      loginState: {
        ...state.loginState,
        authToken: null,
        email: action.email,
        emailSubmitted: true,
        // Reinforce defaults. Can take this out.
        emailProcessed: false,
        passwordSubmitted: false,
      },
    };
  }

  if (action.type === PRE_LOGIN_FAILURE) {
    return {
      ...state,
      loginState: {
        ...state.loginState,
        authToken: null,
        emailSubmitted: false,
        // Reinforce defaults. Can take this out?
        emailProcessed: false,
        passwordSubmitted: false,
        // Clear the passphrase field but not the email field.
        passphrase: "",
      },
    };
  }

  if (action.type === PRE_LOGIN_SUCCESS) {
    return {
      ...state,
      loginState: {
        ...state.loginState,
        authToken: null,
        emailSubmitted: true,
        emailProcessed: true,
        passwordSubmitted: false,
        passphrase: "",
      },
    };
  }

  if (action.type === UPDATE_PASSPHRASE) {
    return {
      ...state,
      loginState: {
        ...state.loginState,
        passphrase: action.passphrase,
      },
    };
  }

  if (action.type === LOGIN_BEGIN) {
    return {
      ...state,
      loginState: {
        ...state.loginState,
        authToken: null,
        passphrase: action.passphrase,
        passwordSubmitted: true,
      },
    };
  }

  if (action.type === LOGIN_FAILURE) {
    return {
      ...state,
      loginState: {
        ...state.loginState,
        authToken: null,
        passwordSubmitted: false,
        // Clear the passphrase field also.
        passphrase: "",
        emailProcessed: false,
        emailSubmitted: false,
      },
    };
  }

  if (action.type === LOGIN_SUCCESS) {
    var fail = false;

    try {
      document.title = Constants.webAppName + " (for " + action.name + ")";

      // Cache in session storage.
      sessionStorage.setItem("auth_token", action.token);
      sessionStorage.setItem("name", action.name);
    } catch (err) {
      fail = true;
    }

    if (fail) {
      try {
        sessionStorage.clear();
      } catch (err) {
      }
    }

    return {
      ...state,
      loginState: {
        ...state.loginState,
        authToken: action.token,
        name: action.name,
        // Leave passwordSubmitted to true
        // which means that the input field will remain disabled.
      },
    };
  }

  if (action.type === FAST_LOGIN) {
    try {
      document.title = Constants.webAppName + " (for " + action.name + ")";
    } catch (err) {
    }

    return {
      ...state,
      loginState: {
        ...state.loginState,
        authToken: action.token,
        name: action.name,
        passwordSubmitted: true,
        passphrase: "",
        emailProcessed: true,
        emailSubmitted: true,
      },
    };
  }

  if (action.type === PREFETCH_LANDING_STATE_BEGIN) {
    return {
      ...state,
      loginState: {
        ...state.loginState,
        // HACK: Implies that PREFETCH_LANDING_STATE_BEGIN happens
        // right after LOGIN_SUCCESS
        // Clear the passphrase field; we don't want it stored.
        passphrase: "",
      },
      prefetchState: {
        ...state.prefetchState,
        prefetchStatus: OperationState.IN_PROGRESS,
        listsMetadataById: null,
      },
    };
  }

  if (action.type === PREFETCH_LANDING_STATE_FAILURE) {
    return {
      ...state,
      prefetchState: {
        ...state.prefetchState,
        prefetchStatus: OperationState.FAILURE,
        errorText: action.description,
        listsMetadataById: null,
        listsByConceptType: null,
        summariesByConceptType: null,
      },
    };
  }

  if (action.type === PREFETCH_LANDING_STATE_SUCCESS) {
    // Because only part of the state might get fetched, we need to leverage
    // already cached state for stuff that was not fetched.
    var lists = [
      action.lists[ConceptType.SPELLING - 1] === null ?
        {...state.prefetchState.listsByConceptType[ConceptType.SPELLING - 1]} :
        {...action.lists[ConceptType.SPELLING - 1]},
      action.lists[ConceptType.VOCAB - 1] === null ?
        {...state.prefetchState.listsByConceptType[ConceptType.VOCAB - 1]} :
        {...action.lists[ConceptType.VOCAB - 1]},
      action.lists[ConceptType.ROOTS - 1] === null ?
        {...state.prefetchState.listsByConceptType[ConceptType.ROOTS - 1]} :
        {...action.lists[ConceptType.ROOTS - 1]},
    ];

    var summaries = [
      action.summaries[ConceptType.SPELLING - 1] === null ?
        {...state.prefetchState.summariesByConceptType[ConceptType.SPELLING - 1]} :
        {...action.summaries[ConceptType.SPELLING - 1]}, 
      action.summaries[ConceptType.VOCAB - 1] === null ?
        {...state.prefetchState.summariesByConceptType[ConceptType.VOCAB - 1]} :
        {...action.summaries[ConceptType.VOCAB - 1]}, 
      action.summaries[ConceptType.ROOTS - 1] === null ?
        {...state.prefetchState.summariesByConceptType[ConceptType.ROOTS - 1]} :
        {...action.summaries[ConceptType.ROOTS - 1]},
    ];

    // TODO: Remove this once this has been tested that it works.
    // const spelling_review_list = lists.shift();
    // const vocab_review_list = lists.shift();

    // Remap the lists by ID. 
    var lists_metadata_by_id = {};
    for (var ii = 0; ii < 3; ii++) {
      for (var jj in lists[ii]) {
        var cur_list = lists[ii][jj];
        lists_metadata_by_id[cur_list.id] = cur_list;
      }
    }

    return {
      ...state,
      prefetchState: {
        ...state.prefetchState,
        prefetchStatus: OperationState.SUCCESS,
        listsMetadataById: lists_metadata_by_id,
        listsByConceptType: lists,
        summariesByConceptType: summaries,
      },
      // Reset fetchStatus 
      // (but NOT conceptCollection or listComplete, it is used elsewhere)
      selectedListState: {
        ...state.selectedListState,
        fetchStatus: null,
        // NOTE: Do NOT reset listComplete or conceptCollection
        // until a new list has been selected.
      },
    };
  }

  if (action.type === PREFETCH_WORDS_BEGIN) {
    return {
      ...state,
      selectedListState: {
        ...state.selectedListState,
        fetchStatus: OperationState.IN_PROGRESS,
        listId: action.listId,
        conceptCollection: null,
        listComplete: null,
      },
    };
  }

  if (action.type === PREFETCH_WORDS_FAILURE) {
    return {
      ...state,
      selectedListState: {
        // Intentional: do not pull in existing state,
        // override all the selectedListState.
        fetchStatus: OperationState.FAILURE,
        listId: null,
        subListMetadata: null,
        conceptCollection: null,
        listComplete: null,
      },
    };
  }

  if (action.type === PREFETCH_WORDS_RESET) {
    return {
      ...state,
      selectedListState: {
        // Intentional: do not pull in existing state,
        // override all the selectedListState.
        fetchStatus: null,
        listId: null,
        subListMetadata: null,
        conceptCollection: null,
        listComplete: null,
      },
    };
  }

  if (action.type === PREFETCH_WORDS_SUCCESS) {
    var selected_list = state.prefetchState.listsMetadataById[action.listId];
    var words_in_list = obfuscateHints(action.words);

    const selected_list_state = initializeSelectedListState(
      selected_list,
      action.subListHandle,
      action.reviewMode,
      words_in_list,
    );

    const first_concept_state = selected_list_state.queueCurrent.dequeue();
    // console.log(first_word_data);

    const options = first_concept_state.isMultipleChoiceCard() ?
      first_concept_state.getMultipleChoiceOptions(): [];

    return {
      ...state,
      selectedListState: selected_list_state,
      conceptState: first_concept_state,
      currentCardState: initializeCurrentCardState(first_concept_state, options),
    };
  }

  if (action.type === LOG_REVIEWED_WORDS_BEGIN) {
    return {
      ...state,
      selectedListState: {
        ...state.selectedListState,
        uploadStatus: OperationState.IN_PROGRESS,
      },
    };
  }

  if (action.type === LOG_REVIEWED_WORDS_SUCCESS) {
    return {
      ...state,
      selectedListState: {
        ...state.selectedListState,
        uploadStatus: OperationState.SUCCESS,
      },
    };
  }

  if (action.type === LOG_REVIEWED_WORDS_FAILURE) {
    return {
      ...state,
      selectedListState: {
        ...state.selectedListState,
        uploadStatus: OperationState.FAILURE,
      },
    };
  }

  if (action.type === UPDATE_ROOT) {
    return {
      ...state,
      currentCardState: {
        ...state.currentCardState,
        enteredRoot: action.enteredRoot,
      },
    };
  }

  if (action.type === SUBMIT_ROOT) {
    var original_root = state.conceptState.getWordData().root;
    var transformed_root = transformRoot(action.enteredRoot);
    var spelling = state.conceptState.getWordData().spelling;

    var updated_roots = {...state.selectedListState.updatedRoots};

    if (transformed_root === original_root) {
      updated_roots[spelling] = null;
    } else {
      updated_roots[spelling] = [transformed_root];
    }

    return {
      ...state,
      selectedListState: {
        ...state.selectedListState,
        updatedRoots: updated_roots,
      },
      currentCardState: {
        ...state.currentCardState,
        enteredRoot: action.enteredRoot,
      },
    };
  }

  if (action.type === UPDATE_ANSWER) {
    return {
      ...state,
      currentCardState: {
        ...state.currentCardState,
        answerState: {
          ...state.currentCardState.answerState,
          enteredAnswer: action.enteredAnswer,
          chosenOption: action.chosenOption,
        },
      },
    };
  }

  if (action.type === SUBMIT_ANSWER) {
    concept_state = state.conceptState;

    const word_data = concept_state.getWordData();
    const selected_list_state = state.selectedListState;
    const queue_next = selected_list_state.queueNext;
    const current_card_state = state.currentCardState;
    const orig_entered_answer = current_card_state.answerState.enteredAnswer;

    var correct_count, total_count;
    var all_spellings = [ word_data.spelling ];
    all_spellings.push(...word_data.alt);

    var is_unsure = orig_entered_answer.includes("?");
    var is_correct = false;
    var entered_answer;

    if (concept_state.isMultipleChoiceCard()) {
      var choice_id = current_card_state.answerState.chosenOption;
  
      var entered_option = current_card_state.multipleChoiceOptions[choice_id];
      is_correct = entered_option.isEqual(word_data);
      entered_answer = entered_option.getWord();
    } else {
      entered_answer = orig_entered_answer; 

      // is_correct = false;
      is_correct = all_spellings.some(
        (cur_spelling) => (
          entered_answer.replace(/['\-\s?]/g, '').trim().toLowerCase() === 
          cur_spelling.replace(/['\-\s]/g, '').trim().toLowerCase()
        ),
      );
    }

    correct_count = selected_list_state.correctAnswers;
    if (is_correct) {
      correct_count++;
    }
    total_count = selected_list_state.totalAnswers + 1;

    // We want to treat the dictionary as an immutable object.
    // So we make a local copy, update it, and then set it back into state.
    var incorrectly_answered_words = 
      {...selected_list_state.incorrectlyAnsweredWords};
    var mastered_words = {...selected_list_state.masteredWords};
    var unsure_words = {...selected_list_state.unsureWords};

    var primary_spelling = concept_state.getDisplayableWord();

    if (is_unsure) {
      unsure_words[primary_spelling] = [];
    }
  
    if (concept_state.isAlmostMastered() && is_correct) { 
      mastered_words[primary_spelling] = [];
    }

    if (!is_correct) {
      var misspelling = entered_answer.replace(/\?/g, "")

      if (primary_spelling in incorrectly_answered_words) {
        var misspelled_word_set = new Set(incorrectly_answered_words[primary_spelling]);
        misspelled_word_set.add(misspelling)
        incorrectly_answered_words[primary_spelling] = Array.from(misspelled_word_set);
      } else {
        incorrectly_answered_words[primary_spelling] = [ misspelling ];
      }
    }

    return {
      ...state,
      selectedListState: {
        ...state.selectedListState,
        queueNext: queue_next,
        correctAnswers: correct_count,
        totalAnswers: total_count,
        masteredWords: mastered_words,
        unsureWords: unsure_words,
        incorrectlyAnsweredWords: incorrectly_answered_words,
      },
      conceptState: concept_state,
      currentCardState: {
        ...state.currentCardState,
        answerState: {
          ...state.currentCardState.answerState,
          answerGiven: true,
          answerIsCorrect: is_correct,
        },
      },
    };
  }

  if (action.type === NEXT_WORD_REQUESTED) {
    concept_state = state.conceptState;
    is_correct = state.currentCardState.answerState.answerIsCorrect;

    const word_data = concept_state.getWordData();
    const selected_list_state = state.selectedListState;
    const current_card_state = state.currentCardState;
    const concept_collection = selected_list_state.conceptCollection;

    mastered_words = {...selected_list_state.masteredWords};
 
    // First update problem reports from PREVIOUS word.
    const problem_state = current_card_state.problemState;
    var prob_str = "";
    prob_str = prob_str + ((problem_state.audio === true) ? "A" : "-");
    prob_str = prob_str + ((problem_state.root === true) ? "R" : "-");
    prob_str = prob_str + ((problem_state.meanings === true) ? "M" : "-");
    prob_str = prob_str + ((problem_state.notes === true) ? "N" : "-");

    var problem_reports = {...selected_list_state.problemReports};
    if (prob_str !== "----") {
      problem_reports[word_data.displayableWord] = [ prob_str ];
    }

    // Update voice index in two places - voice_indices + word_data.
    const voice_index = current_card_state.audioState.voiceIndex;
    var voice_indices = {...selected_list_state.voiceIndices};

    // Only create a new entry in voice_indices IF the index was explicitly
    // changed from what it was before. This ensures we don't create an entry
    // for roots.
    if (concept_state.getWordData().voiceIndex !== voice_index) {
      voice_indices[word_data.uniqueKey] = [ voice_index ];
      word_data.voiceIndex = voice_index;
    }

    // The position of registerAnswer is super-important. It has to be after
    // the voice-index manipulation above (which operates on the previously 
    // seen concept, but before the code further below (Which operates on the
    // new concept card).
    if (selected_list_state.subListMetadata.isBrowseMode()) {
      // Duplication of logic in SUBMIT_ANSWER, because SUBMIT_ANSWER is not
      // called in browse-mode. Note that we use isMastered, not isAlmostMastered
      // since registerAnswer has already been called above.
      concept_state = concept_state.registerAnswer(true);
      primary_spelling = concept_state.getDisplayableWord();
      if (concept_state.isMastered()) {
        mastered_words[primary_spelling] = [];
      }
    } else {
      concept_state = concept_state.registerAnswer(is_correct);
    }

    if (concept_collection.getLength() === Object.keys(mastered_words).length) {
      return { 
        ...state,
        selectedListState: {
          ...state.selectedListState,
          masteredWords: mastered_words,
          problemReports: problem_reports,
          voiceIndices: voice_indices,
          listComplete: true,
        },
      };
    }

    var options;
    var queue_current = selected_list_state.queueCurrent;
    var queue_next = selected_list_state.queueNext;

    if (concept_state.isMastered() || concept_state.shouldRequeue()) {
      if (concept_state.shouldRequeue()) {
        queue_next.enqueue(concept_state);
      }

      // At this point, the concept was either mastered, or requeued.
      // Either way, we need to pick a new concept.

      // If queue_current is exhausted, we swap.
      if (queue_current.getLength() === 0) {
        var tmp_queue = queue_current;
        queue_current = queue_next;
        queue_next = tmp_queue;
      }

      concept_state = queue_current.dequeue();
    }

    // Reload options if we are still in multiple choice card
    options = concept_state.isMultipleChoiceCard() ?
      concept_state.getMultipleChoiceOptions() : [];
 
    return { 
      ...state,
      selectedListState: {
        ...state.selectedListState,
        masteredWords: mastered_words,
        problemReports: problem_reports,
        voiceIndices: voice_indices,
        queueCurrent: queue_current,
        queueNext: queue_next,
        uploadStatus: null,
      },
      prefetchState: {
        ...state.prefetchState,
        prefetchStatus: null,
      },
      // Dangerous to clone it here and there's no need.
      conceptState: concept_state,
      currentCardState: initializeCurrentCardState(concept_state, options),
    };
  }

  if (action.type === SET_AUDIO_STATE) {
    var new_autoplay = action.autoPlay === null ?
      state.currentCardState.audioState.autoPlay :
      action.autoPlay;

    var new_voice_index = action.voiceIndex === null ?
      state.currentCardState.audioState.voiceIndex :
      action.voiceIndex;

    return {
      ...state,
      currentCardState: {
        ...state.currentCardState,
        audioState: {
          ...state.currentCardState.audioState,
          autoPlay: new_autoplay,
          voiceIndex: new_voice_index,
        },
      },
    };
  }
      
  if (action.type === PROBLEM_REPORTED) {
    return {
      ...state,
      currentCardState: {
        ...state.currentCardState,
        problemState: action.problemState,
      },
    };
  }

  return {...state};
}

const store = createStore(reducer, applyMiddleware(thunk));
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <SpellableRoot />
  </Provider>
);

registerServiceWorker();
