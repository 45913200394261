import React from 'react';
import { connect } from 'react-redux';

import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

class AudioButton extends React.Component {
  constructor(props) {
    super(props);
    this.audioID = "audio_" + this.props.fileKey;
    this.pronounceWord = this.pronounceWord.bind(this);
    this.audioElementRef = React.createRef();
  }

  async pronounceWord() {
    const audio_elem = this.audioElementRef.current;
    audio_elem.load();
    var play_promise = audio_elem.play();
    if (play_promise !== undefined) {
      try {
        await play_promise;
      } catch (err) {
        // console.log(err);
      } 
      // play_promise.then(
      //  function() {}
      // ).catch(
      //  function(error) {}
      // );
    }

    this.props.afterAudioPlay();
  }

  componentDidMount() {
    if (!this.props.isHidden && this.props.autoPlay) {
      this.pronounceWord();
    }
  }

  componentDidUpdate(prev_props) {
    if (!this.props.isHidden && this.props.autoPlay) {
      this.pronounceWord();
    }
  }

  componentWillUnmount() {
    const audio_elem = this.audioElementRef.current;
    audio_elem.pause();
    audio_elem.innerHTML = "";
    audio_elem.src = "";
    audio_elem.load();
  }

  render() {
    const base_file_path = 'https://spellableaudio.s3.amazonaws.com/';

    const sx_params = this.props.isHidden ?
      { display: "none" } : {};

    return (
      <Stack 
        sx={sx_params}
        alignItems="center" 
        spacing={1}>
        <audio 
          id={this.audioID}
          ref={this.audioElementRef}
          preload="auto">
          <source 
            src={base_file_path + this.props.fileKey + ".mp3"}
            type='audio/mpeg;codecs="mp3"' />
          <source 
            src={base_file_path + this.props.fileKey + ".ogg"} 
            type='audio/ogg;codecs="vorbis"' />
        </audio>
        <Button
          sx={{ 
            color: 'text.primary', 
            border: 2, 
            borderColor: "text.disabled" 
          }}
          onClick={this.pronounceWord}>
          <VolumeUpIcon sx={{ fontSize: "40px" }} />
        </Button>
        <Typography variant="subtitle2">
          {this.props.text}
        </Typography>
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AudioButton);
