import React from 'react';
import { connect } from 'react-redux';
import { updateAnswer } from './actions.js';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

class MultipleChoiceInput extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.firstOption = React.createRef();
  }

  componentDidMount() {
    if (this.props.chosenOption === -1) {
      this.firstOption.current.focus();
    }
  }

  componentDidUpdate(prev_props) {
    if (this.props.chosenOption === -1) {
      this.firstOption.current.focus();
    }
  }

  keyDown(e, id) {
    var option_id = null;

    if (e.which === 32 || e.which === 13) {
      option_id = id;
    }

    if (e.which >= 65 && e.which <= 90) {
      option_id = e.which - 65;
    }

    if (e.which >= 97 && e.which <= 122) {
      option_id = e.which - 97;
    }

    /*
    if (e.which >= 37 && e.which <= 38) {
      option_id = ((id - 1) < 0) ? 
        this.props.options.length - 1 :
        id - 1;
    }

    if (e.which >= 39 && e.which <= 40) {
      option_id = ((id + 1) > (this.props.options.length - 1)) ? 
        0 :
        id + 1;
    } */

    if ((option_id !== null) && 
        (option_id < this.props.options.length)) {
      this.props.updateAnswer(
        /* entered_answer = */ this.props.options[option_id].getWord(), 
        /* chosen_option = */ option_id,
      );

      e.preventDefault();
    }
  }

  onClick(id) {
    if (this.props.answerIsLocked === true) { return; }
    
    this.props.updateAnswer(
      /* entered_answer = */ this.props.options[id].getWord(), 
      /* chosen_option = */ id,
    );
  }

  renderRadioControl(id, text) {
    const is_chosen = (id === this.props.chosenOption);

    const radio_sx = {
      border: 1,
      borderRadius: 2,
      padding: 0.5,
      height: 24,
      width: 24,
      lineHeight: 1,
      textAlign: "center",
      fontWeight: "bold",
      color: is_chosen ? "background.default" : "text.primary",
      backgroundColor: is_chosen ? "text.primary" : "background.default",
    };

    if (id === 0) {
      return (
        <Box
          sx={radio_sx}
          borderColor="text.primary"
          tabIndex="0"
          ref={this.firstOption}
          onClick={(e) => { this.onClick(id); }}
          onKeyDown={(e) => { this.keyDown(e, id); }}>
          {text}
        </Box>
      );
    } else {
      return (
        <Box
          sx={radio_sx}
          borderColor="text.primary"
          tabIndex="0"
          optionvalue={id}
          onClick={(e) => { this.onClick(id); }}
          onKeyDown={(e) => { this.keyDown(e, id); }}>
          {text}
        </Box>
      );
    }
  }

  renderOption(id, option_data) {
    const answer_given = this.props.answerIsLocked;
    const title = option_data.getDisplayableMeaning(!answer_given);

    // console.log("Answer " + this.props.answerWordData.spelling);
    // console.log("Word = " + word);

    const is_correct_answer = option_data.isEqual(this.props.answerWordData);
    // console.log(is_correct_answer);

    var option_sx = {
      padding: 1,
    };
    var alpha_id = String.fromCharCode("A".charCodeAt(0) + id);

    if (is_correct_answer && answer_given) {
      option_sx = {
        border: 1,
        padding: 1,
      };
    }

    // TODO: Change once patent has been filed.
    const word = option_data.getWord();
    const suffix = (answer_given) ? "  [ " + word + " ]" : "";
    // const suffix = "";
 
    return (
      <Stack 
        direction="row" 
        sx={option_sx}
        spacing={1}
        key={alpha_id}>

        {this.renderRadioControl(id, alpha_id)}
  
        <span>
          {title}
          <strong> {suffix} </strong>
        </span>
      </Stack>
    );
  }
  
  render() {
    var option_elements = [];

    for (var ii = 0; ii < this.props.options.length; ii++) {
      var current_element = this.props.options[ii];
      option_elements.push(
        this.renderOption(ii, current_element),
      );
    }
       
    return (
      <Stack sx={{marginTop: 4}} spacing={1}>
        <div className="multiple-choice-heading">Choose one of the options:</div>
        <Stack spacing={1}>
          {option_elements}
        </Stack>
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  updateAnswer,
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceInput);

