import React from 'react';
import { connect } from 'react-redux';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

class SpellableSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.state = { checked: this.props.defaultChecked };
  }

  onChangeHandler(e) {
    this.setState({ checked: e.target.checked });
    this.props.onChange(e.target.checked);
  }

  render() {
    const switch_elem = (
      <Switch
        size="medium"
        checked={this.state.checked}
        onChange={this.onChangeHandler} />
    );

    return (
      <FormControlLabel
       control={switch_elem}
       label={<Typography variant="body2">{this.props.label}</Typography>}
       size="medium" />
    );
  }
} 

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SpellableSwitch);

