import React from 'react';
import { connect } from 'react-redux';
import { updateRoot, submitRoot } from './actions.js';

import SpellableInput from './SpellableInput.js';

class RootInput extends React.Component {
  constructor(props) {
    super(props);
    this.onNewInput = this.onNewInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onNewInput(root_so_far) {
    this.props.updateRoot(root_so_far);
  }

  onSubmit(entered_root) {
    this.props.submitRoot(entered_root);
  }

  render() {
    const class_name = 
      this.props.shouldShow ? "root-input" : "root-input invisible";
    return (
      <div className = {class_name}>
        Update Root:
        <span>&nbsp;&nbsp;</span>
        <SpellableInput
          onChange={this.onNewInput}
          onSubmit={this.onSubmit}
          value={this.props.enteredRoot}
          disabled={false} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shouldShow: state.currentCardState.answerState.answerGiven,
  };
}

const mapDispatchToProps = {
  updateRoot,
  submitRoot,
}

export default connect(mapStateToProps, mapDispatchToProps)(RootInput);
