import OptionData from './OptionData.js';
import ReviewMode from './ReviewMode.js';

const NUM_OPTIONS = 5;

export const CardType = {
  BROWSE: "BROWSE_CARD",
  VOCAB: "VOCAB_CARD",
  ROOT: "ROOT_CARD",
  REVERSE_VOCAB: "REVERSE_VOCAB_CARD",
  SENTENCE: "SENTENCE_CARD",
  SPELLING: "SPELLING_CARD",
};

export default class ConceptState {
  constructor(
    concept_collection,
    concept_type,
    review_mode,
    primary_word_data, 
    chained_word_data, 
    stage = 0,
    cumulative_is_correct = true,
  ) {
    this.conceptCollection = concept_collection;
    this.conceptType = concept_type;
    this.reviewMode = review_mode;
    this.primaryWordData = primary_word_data;
    this.chainedWordData = chained_word_data;
    this.stage = stage;
    this.cumulativeIsCorrect = cumulative_is_correct;

    // meanings and numStages are always inferred
    // so don't need to be passed in when replicating
    // ConceptState
    this.meanings = this.primaryWordData.meanings;

    if (this.isBrowseMode()) {
      this.numStages = 1;
    } else if (this.conceptType === "ROOTS") {
      if (this.chainedWordData != null) {
        this.numStages = this.meanings.length + 1;
      } else {
        this.numStages = this.meanings.length;
      }
    } else if (this.conceptType === "VOCAB") {
      this.numStages = this.meanings.length;
    } else {
      this.numStages = 1;
    }
  }

  getCardType() {
    if (this.isBrowseMode()) {
      return CardType.BROWSE;
    }
    
    if (this.conceptType === "ROOTS") {
      // This logic handles the case when chained word
      // is absent (next two if statements)
      if (this.stage < this.meanings.length) { 
        return CardType.ROOT;
      }

      if (this.stage === (this.numStages - 1)) {
        return CardType.SPELLING;
      }

      return null;
    } else if (this.conceptType === "VOCAB") {
      return CardType.VOCAB;
    } else if (this.conceptType === "SPELLING") {
      return CardType.SPELLING;
    }

    return null;
  }

  getPrimaryWordData() {
    return this.primaryWordData;
  }

  getWordData() {
    var result;

    if ((this.conceptType === "ROOTS") && 
        (this.stage === this.meanings.length)) {
      result = this.chainedWordData;
    } else {
      result = this.primaryWordData;
    }

    return result;
  }

  getDisplayableWord() {
    return this.primaryWordData.displayableWord;
  }

  isMultipleChoiceCard() {
    return (
      (this.getCardType() === CardType.ROOT) ||
      (this.getCardType() === CardType.VOCAB)
    );
  }

  isMastered() {
    return (
      (this.stage === this.numStages) && 
      (this.cumulativeIsCorrect)
    );
  }

  isAlmostMastered() {
    return (
      (this.stage === (this.numStages - 1)) && 
      (this.cumulativeIsCorrect)
    );
  }

  isBrowseMode() {
    return (this.reviewMode === ReviewMode.BROWSE_MODE);
  }

  shouldRequeue() {
    if (this.isMastered()) { return false; }
    if (this.conceptType !== "ROOTS") { return true; }

    // To handle the case where chained word is absent.
    return (this.stage < this.meanings.length);
  }

  getMultipleChoiceOptions() {
    // Always pick multiple choice from the primaryWordData (for now).
    const word_data = this.primaryWordData;
    const concept_collection = this.conceptCollection;
    const append_variants = (this.conceptType === "ROOTS");

    var failed_match_count = 0;
    var options = [];

    // Seed the right answer first.
    options.push(
      new OptionData(
        /* word_data = */ word_data,
        /* append_variants = */ append_variants,
        /* meaning_index = */ this.stage,
      ),
    );
   
    // If there are less than NUM_OPTIONS words in the list,
    // we return only as many as we can.
    while (options.length < NUM_OPTIONS && 
           options.length < concept_collection.getLength()) {
      // Pick a random word.
      const random_index = Math.floor(Math.random() * concept_collection.getLength());
      const random_concept = concept_collection.getConceptAtIndex(random_index);
      const random_word_data = random_concept.getPrimaryWordData();

      // If it is the same as an already chosen word, skip.
      var is_duplicate = false;
      for (var ii = 0; ii < options.length; ii++) {
        if (options[ii].isEqual(random_word_data)) {
          is_duplicate = true;
          break;
        }
      }

      if (is_duplicate) {
        continue;
      }
  
      var current_option = new OptionData(random_word_data, append_variants);

      // Skip obsolete meanings.
      if (current_option.getDisplayableMeaning().includes("(obsolete)")) {
        continue;
      }

      if (current_option.getDisplayableMeaning().includes("(archaic)")) {
        continue;
      }
 
      if (failed_match_count < 10 && 
          !options[0].hasCommonPartOfSpeech(current_option)) {
        failed_match_count++;
        continue;
      } 
 
      // This is good. Put it in the array.
      options.push(current_option);
    }

    // Swap randomly at the end to ensure the right answer
    // is not always the first. But make sure that index 0
    // is in the running.
    const swap_index = Math.min(
      options.length - 1,
      Math.floor(Math.random() * options.length),
    );

    const temp = options[swap_index];
    options[swap_index] = options[0];
    options[0] = temp;

    return options;
  }

  registerAnswer(is_correct) {
    this.cumulativeIsCorrect = this.cumulativeIsCorrect && is_correct;
    this.stage = this.stage + 1;

    if (!this.cumulativeIsCorrect) {
      // Reset state to start.
      if (this.stage >= this.numStages) { 
        this.stage = 0;
        this.cumulativeIsCorrect = true;
      }
    }

    return new ConceptState(
      /* concept_collection = */ this.conceptCollection,
      /* concept_type = */ this.conceptType,
      /* review_mode = */ this.reviewMode,
      /* primary_word_data = */ this.primaryWordData,
      /* chained_word_data = */ this.chainedWordData,
      /* stage = */ this.stage,
      /* cumulative_is_correct = */ this.cumulativeIsCorrect,
    ); 
  }

}
