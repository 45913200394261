import React from 'react';
import { connect } from 'react-redux';

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

class WordNotes extends React.Component {
  renderExamples() {
    var examples = [];
    var umw_url;

    for (var ii = 0; ii < this.props.examples.length; ii++) {
      umw_url = "https://unabridged.merriam-webster.com/unabridged/" +
        this.props.examples[ii];
     
      if (ii === this.props.examples.length - 1) { 
        examples.push(
          <React.Fragment>
            <Link 
              href={umw_url} 
              key={"examples_" + ii}
              target="_blank" 
              rel="noopener noreferrer">
              {this.props.examples[ii]}
            </Link>.
          </React.Fragment>
        );
      } else {
        examples.push(
          <React.Fragment>
            <Link 
              href={umw_url} 
              key={"examples_" + ii}
              target="_blank" 
              rel="noopener noreferrer">
              {this.props.examples[ii]}
            </Link>,&nbsp;
          </React.Fragment>
        );
      }
    }

    return (
      <Typography variant="body2">
        Examples: {examples}
      </Typography>
    );
  }
  
  render() {
    var notes = [];
    var all_spellings;
    var ii;
    for (ii in this.props.notes) {
      notes.push(
        <ListItem 
          disableGutters
          sx={{ display: 'list-item' }} 
          key={"notes_" + ii}>
          {this.props.notes[ii]}
        </ListItem>
      );
    }

    if (this.props.examples.length > 0) {
      notes.push(
        <ListItem 
          disableGutters
          sx={{ display: 'list-item' }} 
          key={"notes_" + notes.length}>
          {this.renderExamples()}
        </ListItem>
      );
    }

    const mw_url = "https://www.merriam-webster.com/dictionary/" +
      this.props.word + "#etymology";
    const umw_url = "https://unabridged.merriam-webster.com/unabridged/" +
      this.props.word;

    all_spellings = [ this.props.word ];
    if (this.props.alt !== null && this.props.alt.length > 0) {
      all_spellings.push(...this.props.alt);
    }

    const displayed_text = all_spellings.join(" / ");

    notes.push(
      <ListItem 
        disableGutters
        sx={{ display: 'list-item' }} 
        key={"notes_" + notes.length}>
        <Typography variant="body2">
          {"Merriam-Webster entry:"}&nbsp;
          <Link 
            href={mw_url} 
            target="_blank" 
            rel="noopener noreferrer">
            {displayed_text}
          </Link>
          &nbsp;{"("}
          <Link 
            href={umw_url} 
            target="_blank" 
            rel="noopener noreferrer">
            {"click for unabridged"}
          </Link>
          {")."}
        </Typography>
      </ListItem>
    );

    if (notes.length === 0) {
      return (
        <List />
      );
    }

    const visibility = this.props.shouldShow ?  "visible" : "hidden";

    return (
      <Stack 
        alignItems="left" 
        sx={{ mt: 3 }}
        visibility={visibility}>
        <Typography 
          variant="body1" 
          sx={{ fontWeight: 'bold' }}>
          Note(s):
        </Typography>
        <List 
          dense 
          sx={{ marginInlineStart: 3 }} 
          style={{ listStyleType: "disc" }}>
          {notes}
        </List>
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WordNotes);
