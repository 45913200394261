import ConceptState from './ConceptState.js';

export default class ConceptCollection {
  constructor(words, concept_type, review_mode) {
    var ii, split_index;
    var primary_word_list, secondary_word_dict;

    if (concept_type === "ROOTS") {
      for(ii = 0; ii < words.length; ii++) {
        if (words[ii].chainedId === null) { break; }
      }

      split_index = ii;

      primary_word_list = words.slice(0, split_index);

      secondary_word_dict = {};
      for(ii = split_index; ii < words.length; ii++) {
        secondary_word_dict[words[ii].displayableWord] = words[ii];
      }

    } else {
      primary_word_list = words;
      secondary_word_dict = {};
    }

    const concepts = new Array(primary_word_list.length).fill(null);

    var concept_state, primary_word_data, chained_word_data;

    for (ii = 0; ii < primary_word_list.length; ii++) {
      primary_word_data = primary_word_list[ii];
      chained_word_data = secondary_word_dict[primary_word_data.chainedId] || null;
      //  (primary_word_data.chainedId !== null && secondary_word_dict !== null) ?
      //    secondary_word_dict[primary_word_data.chainedId] :
      //    null;

      concept_state = new ConceptState(
        /* concept_collection = */ this,
        /* concept_type = */ concept_type,
        /* review_mode = */ review_mode,
        /* primary_word_data = */ primary_word_data,
        /* changed_word_data = */ chained_word_data,
      );

      concepts[ii] = concept_state;
    }

    this.concepts = concepts;
    this.conceptType = concept_type;
    this.listLength = concepts.length;

    Object.freeze(this);
  }

  getLength() {
    return this.concepts.length;
  }

  getConceptType() {
    return this.conceptType;
  }

  getConceptAtIndex(idx) {
    return this.concepts[idx];
  }
}
