const Constants = {
  // webAppName: "Mighty Words",
  webAppName: "Spellable",

  // graphQLUrl: "https://www.mightywords.co/graphql",
  graphQLUrl: "https://sethibs.pythonanywhere.com/graphql",
  // graphQLUrl: "http://127.0.0.1:5000/graphql",

  llc: "Neev, LLC",
  logoLink: "https://s3.amazonaws.com/thousandwords/mighty_words_small.png",

};

export default Constants;


