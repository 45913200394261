import React from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

class WordSpelling extends React.Component {
  render() {
    var text, variant_elem, visibility;

    // Need a fake placeholder to make sure all cards look
    // consistent.
    variant_elem = (
      <Typography variant="subtitle">
        {" "}
      </Typography>
    );

    text = this.props.spelling;

    if (this.props.alt !== null && this.props.alt.length > 0) {
      if (this.props.renderVariantsInTitle === true) {
        text = text + ", " + this.props.alt.join(", ");
      } else {
        variant_elem = (
          <Typography variant="subtitle">
            {"Variant(s): " + this.props.alt.join(", ")}
          </Typography>
        );
      }
    }

    visibility = (this.props.shouldShow) ? "visible" : "hidden";

    return (
      <Stack alignItems="center" visibility={visibility} >
        <Typography variant="h4">
          {text}
        </Typography>
        {variant_elem}
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WordSpelling);
