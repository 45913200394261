import React from 'react';
import { connect } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';

class SpellableAccordionSummary extends React.Component {
  render() {
    const background_color = (theme: Theme) => (
      theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)'
    );

    return (
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: background_color }}>
        {this.props.children}
      </AccordionSummary>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SpellableAccordionSummary);

