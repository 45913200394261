import React from 'react';
import { connect } from 'react-redux';

import Stack from '@mui/material/Stack';

import AudioControl from './AudioControl.js';
import NextWordButton from './NextWordButton.js';
import ReportFeedback from './ReportFeedback.js';
// import RootInput from './RootInput.js';
import SpellableScore from './SpellableScore.js';
import WordInput from './WordInput.js';
import WordMeanings from './WordMeanings.js';
import WordNotes from './WordNotes.js';
import WordRoot from './WordRoot.js';
import WordSpelling from './WordSpelling.js';

class SpellingCard extends React.Component {

  render() {
    const answer = this.props.wordData.spelling; 
    const alternate_spellings = this.props.wordData.alt;
    const displayed_answer = this.props.wordData.displayableWord;
    const unique_key = this.props.wordData.uniqueKey;
    const notes = this.props.wordData.notes;
  

    /*
      ADD IT BACK below WordRoot if needed.

        <RootInput 
          enteredRoot={this.props.enteredRoot} />
    */

    return (
      <Stack alignItems="center" spacing={2}>
        <WordSpelling 
          shouldShow={this.props.answerGiven}
          spelling={displayed_answer} 
          alt={alternate_spellings} />

        <AudioControl 
          uniqueKey={unique_key} />

        <WordRoot />

        <WordMeanings />

        <WordNotes
          word={answer}
          alt={alternate_spellings}
          examples={[]}
          notes={notes}
          shouldShow={this.props.answerGiven} />

        <WordInput
          options={[]}
          enteredAnswer={this.props.enteredAnswer}
          answerIsLocked={this.props.answerGiven}
          answerIsCorrect={this.props.answerIsCorrect} />

        <NextWordButton 
          sx={{  paddingTop: 2, paddingBottom: 3 }}
          shouldShow={this.props.answerGiven} />

        <SpellableScore />
  
        <ReportFeedback />

      </Stack>
    );
  }
}

function mapStateToProps(state) {
  const word_data = state.conceptState.getWordData();

  return {
    wordData: word_data,
    enteredAnswer: state.currentCardState.answerState.enteredAnswer,
    enteredRoot: state.currentCardState.enteredRoot,
    answerGiven: state.currentCardState.answerState.answerGiven,
    answerIsCorrect: state.currentCardState.answerState.answerIsCorrect,
  };
}

export default connect(mapStateToProps)(SpellingCard);
