import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DarkModeSwitch from './DarkModeSwitch.js';
import SpellableInput from './SpellableInput.js';
import SpellableLogo from './SpellableLogo.js';

import { 
  updateEmail,
  preLogin,
  updatePassphrase, 
  login,
  fastLogin,
} from './actions.js';

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);

    this.submitEmail = this.submitEmail.bind(this);
    this.submitPassphrase = this.submitPassphrase.bind(this);
    this.onButtonPress = this.onButtonPress.bind(this);

    this.authToken = null;
    this.name = null;

    try {
      this.cachedAuthToken = sessionStorage.getItem('auth_token');
      this.cachedName = sessionStorage.getItem('name');
      this.cachedDarkMode = sessionStorage.getItem('dark_mode');
    } catch (e) {}
  }

  submitEmail(email) {
    this.props.preLogin(email);
  }

  submitPassphrase(passphrase) {
    this.props.login(this.props.email, passphrase);
  }

  onButtonPress(e) {
    this.props.fastLogin(this.cachedAuthToken, this.cachedName);
  }

  render() {
    const footer = (
      <footer>
        <Divider 
          variant="fullWidth" 
          sx={{ pt: 30 }} />
        <Stack 
          alignItems="center" 
          justifyContent="space-evenly"
          direction="row" 
          sx={{ pt: 1 }}
          spacing={10}>
          <Link 
            underline="hover"
            sx={{ color: 'text.primary' }}
            component={RouterLink} 
            to="/terms"
            target="_blank" 
            rel="noopener noreferrer">
            Terms of Use
          </Link>
          <Link 
            underline="hover"
            sx={{ color: 'text.primary' }}
            component={RouterLink} 
            to="/privacy" 
            target="_blank" 
            rel="noopener noreferrer">
            Privacy Policy
          </Link>
        </Stack>
      </footer>
    );

    if (this.cachedAuthToken !== null) {
      const title = "Welcome, " + this.cachedName + "!";

      const fast_login = (
        <React.Fragment>
          <SpellableLogo />
          <Typography variant="h5">
          {title}
          </Typography>
          <Typography variant="body1">
            (You've already been logged in on this browser tab)
          </Typography>
            <Button 
              variant="contained"
              sx={{my: 3}}
              onClick={this.onButtonPress}>
              Get Started
            </Button>
          <Typography variant="body2">
            (If you're not {this.cachedName}, close this tab and login from a new tab)
          </Typography>
          <DarkModeSwitch />
        </React.Fragment>
      );

      return (
        <Stack alignItems="center">
          {fast_login}
          {footer}
        </Stack>
      );
    }

    const email_div = (
      <React.Fragment>
        <SpellableLogo />

        <Typography variant="body1">
          A free service for spelling and vocabulary practice!
        </Typography>

        <Typography variant="body1">
          To create a new account,&nbsp;
          <Link 
            component={RouterLink} 
            to="/signup"
            target="_blank" 
            rel="noopener noreferrer">
            click here
          </Link>
        </Typography>

        <Typography variant="body1">
          Read instructions on how to use this site&nbsp;
          <Link 
            component={RouterLink} 
            to="/faq" 
            target="_blank" 
            rel="noopener noreferrer">
            here
          </Link>
        </Typography>

        <Divider 
          sx={{pb: 4}}
          variant="middle" />

        <Typography variant="body1">
          If you're already registered, enter the login email:
        </Typography>

        <SpellableInput 
          onChange={this.props.updateEmail}
          onSubmit={this.submitEmail}
          value={this.props.email}
          disabled={this.props.emailSubmitted} />

      </React.Fragment>
    );

    var passphrase_div = null;

    if (this.props.emailSubmitted) {
      if (this.props.emailProcessed) {
        passphrase_div = (
          <React.Fragment>
            <Typography 
              variant="body1"
              sx={{ pt: 3 }}>
              Enter the Password:
            </Typography>

            <Typography 
              variant="subtitle"
              sx={{ pb: 1 }}>
              (If you did not have a valid password, one has been sent to the email above)
            </Typography>

            <SpellableInput 
              onChange={this.props.updatePassphrase}
              onSubmit={this.submitPassphrase}
              value={this.props.passphrase}
              disabled={this.props.passwordSubmitted} />
        
            <DarkModeSwitch />
          </React.Fragment>
        );
      } else {
        passphrase_div = (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress
              size={25} 
              sx={{color: "text.primary"}}
              style={{marginTop: '20%' }} />
          </div>
        );
      }
    }

    return (
      <Stack alignItems="center">
        {email_div}
        {passphrase_div}
        {footer}
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {
    email: state.loginState.email,
    emailSubmitted: state.loginState.emailSubmitted,
    emailProcessed: state.loginState.emailProcessed,
    passphrase: state.loginState.passphrase,
    passwordSubmitted: state.loginState.passwordSubmitted,
    authToken: state.loginState.authToken,
  };
}

const mapDispatchToProps = {
  updateEmail,
  preLogin,
  updatePassphrase,
  login,
  fastLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
