import React from 'react';
import { connect } from 'react-redux';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Duration from './Duration.js';

class SpellableScore extends React.Component {
  render() {
    var correct_count, total_count, pct_correct;
    var mastered, total;

    correct_count = this.props.correctAnswers;
    total_count = this.props.totalAnswers;
    if (total_count === 0) {
      pct_correct = "--";
    } else {
      pct_correct = Math.round((correct_count * 100.0) / total_count);
    }

    mastered = Object.keys(this.props.masteredWords).length;
    total = this.props.conceptCollection.getLength();

    const total_time_in_ms = Date.now() - this.props.startTimeInMillisecs;
    const total_time_obj = new Duration(total_time_in_ms);
    const total_time_str = total_time_obj.readableString();

    var time_per_word_str;
    if (mastered === 0) {
      time_per_word_str = "--";
    } else {
      const time_per_word_in_ms = Math.round(total_time_in_ms / mastered);
      const time_per_word_obj = new Duration(time_per_word_in_ms);
      time_per_word_str = time_per_word_obj.readableString();
    }
  
      
    return (
      <Stack alignItems="center">
        <Typography variant="body1" align="center">
          Score: {correct_count} / {total_count} = {pct_correct}%
          &nbsp;
          &bull;
          &nbsp;
          Mastered Words: {mastered} / {total}
        </Typography>
        <Typography variant="body1" align="center">
          Total Time: {total_time_str}
          &nbsp;
          &bull;
          &nbsp;
          Average: {time_per_word_str}
        </Typography>
        <Stack direction="row">
          <Typography variant="body1">
            {this.props.subListMetadata.getListSubName()}
            &nbsp;
            &bull;
          </Typography>
          <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>
            &nbsp;
            {this.props.subListMetadata.getListName()}
            &nbsp;
          </Typography>
          <Typography variant="body1" align="center">
            &bull;
            &nbsp;
            {this.props.subListMetadata.isBrowseMode() ? "Browse Mode" : "Test Mode"}
          </Typography>
        </Stack>
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {
    subListMetadata: state.selectedListState.subListMetadata,
    conceptCollection: state.selectedListState.conceptCollection,
    masteredWords: state.selectedListState.masteredWords,
    startTimeInMillisecs: state.selectedListState.startTimeInMillisecs,
    correctAnswers: state.selectedListState.correctAnswers,
    totalAnswers: state.selectedListState.totalAnswers,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SpellableScore);
