import React from 'react';
import { Navigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import SpellableLogo from './SpellableLogo.js';

import { 
  fetchExists,
  fetchLoginInfo,
  fetchVerify,
  genPreVerify,
  genCreateAccount,
} from './graphql.js';

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.onStudentEmailChange = this.onStudentEmailChange.bind(this);
    this.onParentEmailChange = this.onParentEmailChange.bind(this);
    this.onFullNameChange = this.onFullNameChange.bind(this);
    this.onAddressByChange = this.onAddressByChange.bind(this);
    this.onTimezoneChange = this.onTimezoneChange.bind(this);

    this.onStep1ButtonClick = this.onStep1ButtonClick.bind(this);

    this.onParentVerificationCodeChange = 
      this.onParentVerificationCodeChange.bind(this);
    this.onStep2ButtonClick = this.onStep2ButtonClick.bind(this);
  
    this.onStudentVerificationCodeChange = 
      this.onStudentVerificationCodeChange.bind(this);
    this.onStep3ButtonClick = this.onStep3ButtonClick.bind(this);
  
    const selected_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.state = { 
      serverError: false,
      clientError: false,
      studentEmail: "",
      studentEmailExists: false,
      parentEmail: "",
      fullName: "",
      addressBy: "",
      timezone: selected_timezone,
      parentVerificationSent: false,
      parentVerificationCode: "",
      parentVerificationCodeInvalid: false,
      studentVerificationSent: false,
      studentVerificationCode: "",
      studentVerificationCodeInvalid: false,
      accountCreated: false,
      loginCompleted: false,
    };
  }

  onStudentEmailChange(e) {
    const val = e.target.value;
    const exists = (val.trim() === this.state.studentEmail) ?
      this.state.studentEmailExists : false;

    this.setState({ 
      studentEmail: val,
      studentEmailExists: exists,
    });
  }

  onParentEmailChange(e) {
    const val = e.target.value;
    this.setState({ parentEmail: val })
  }

  onFullNameChange(e) {
    const val = e.target.value;
    this.setState({ fullName: val })
  }

  onAddressByChange(e) {
    const val = e.target.value;
    this.setState({ addressBy: val })
  }

  onTimezoneChange(e) {
    const val = e.target.value.trim();
    this.setState({ timezone: val })
  }

  onParentVerificationCodeChange(e) {
    const val = e.target.value;
    const invalid = (val.trim() === this.state.parentVerificationCode) ?
      this.state.parentVerificationCodeInvalid : false;

    this.setState({ 
      parentVerificationCode: val,
      parentVerificationCodeInvalid: invalid,
    });
  }

  onStudentVerificationCodeChange(e) {
    const val = e.target.value;
    const invalid = (val.trim() === this.state.studentVerificationCode) ?
      this.state.studentVerificationCodeInvalid : false;

    this.setState({ 
      studentVerificationCode: val,
      studentVerificationCodeInvalid: invalid,
    });
  }


  isValidEmail(email) {
    // RFC 5322 validation
    const regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])");

    return regex.test(email);
  }

  isValidVerificationCode(code) {
    const regex = new RegExp("([0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4})");

    return regex.test(code.trim());
  }

  getStudentEmailErrorString() {
    if (this.state.studentEmailExists) {
      return "User already exists";
    }

    if (this.state.studentEmail.trim() !== "" && 
      !this.isValidEmail(this.state.studentEmail.trim())) {
      return "Invalid Email";
    }

    return "";
  }

  getParentEmailErrorString() {
    if (this.state.parentEmail.trim() !== "" && 
        !this.isValidEmail(this.state.parentEmail.trim())) {
      return "Invalid Email";
    }

    return "";
  }

  getParentVerificationCodeErrorString() {
    if (this.state.parentVerificationCodeInvalid) {
      return "Code is incorrect";
    }

    const code = this.state.parentVerificationCode;
    if (code.trim() !== "" && !this.isValidVerificationCode(code)) {
      return "Invalid Code";
    }

    return "";
  }

  getStudentVerificationCodeErrorString() {
    if (this.state.studentVerificationCodeInvalid) {
      return "Code is incorrect";
    }

    const code = this.state.studentVerificationCode;
    if (code.trim() !== "" && !this.isValidVerificationCode(code)) {
      return "Invalid Code";
    }

    return "";
  }

  async onStep1ButtonClick() {
    var exists, error_text;

    this.setState({ 
      serverError: false,
      studentEmailExists: false,
      parentVerificationSent: false,
    });

    // First check if the email already exists.
    [exists, error_text] = await fetchExists(this.state.studentEmail);

    if (exists === null) {
      this.setState({ serverError: true });
      return;
    }
 
    if (exists === true) {
      this.setState({ studentEmailExists: true });
      return;
    }
 
    // Next, send out pre-verify request for parent email.
    var pre_verify_ok = await genPreVerify(
      this.state.parentEmail,
      this.state.studentEmail,
      /* verify_parent = */ true,
    );

    if (pre_verify_ok === null) {
      // console.log("Pre verify successfully sent");
      this.setState({ parentVerificationSent: true });
    } else {
      this.setState({ serverError: true });
      return;
    }
  }

  async onStep2ButtonClick() {
    var verify, error_text;

    this.setState({ 
      serverError: false,
      parentVerificationCodeInvalid: false,
      studentVerificationSent: false,
    });

    // First check if the email already exists.
    [verify, error_text] = await fetchVerify(
      this.state.parentEmail,
      this.state.studentEmail,
      /* verify_parent = */ true,
      this.state.parentVerificationCode.trim(),
    );

    if (verify === null) {
      this.setState({ serverError: true });
      return;
    }

    if (verify === false) {
      this.setState({ parentVerificationCodeInvalid: true });
      return;
    }
 
    this.setState({ parentVerificationCodeInvalid: false });
 
    // Next, send out pre-verify request for student email.
    var pre_verify_ok = await genPreVerify(
      this.state.parentEmail,
      this.state.studentEmail,
      /* verify_parent = */ false,
    );

    if (pre_verify_ok === null) {
      // console.log("Pre verify successfully sent");
      this.setState({ studentVerificationSent: true });
    } else {
      this.setState({ serverError: true });
      return;
    }
  }

  async onStep3ButtonClick() {
    var verify, error_text;

    this.setState({ 
      serverError: false,
      studentVerificationCodeInvalid: false,
    });

    // First check if student email is valid.
    [verify, error_text] = await fetchVerify(
      this.state.parentEmail,
      this.state.studentEmail,
      /* verify_parent = */ false,
      this.state.studentVerificationCode.trim(),
    );

    // console.log(verify);
    // console.log(error_text);

    if (verify === null) {
      this.setState({ serverError: true });
      return;
    }

    if (verify === false) {
      this.setState({ studentVerificationCodeInvalid: true });
      return;
    }
 
    this.setState({ studentVerificationCodeInvalid: false });

    // console.log("Entering create account");
    // Create Account
    var create_account_ok = await genCreateAccount(
      /* parent_email = */ this.state.parentEmail,
      /* student_email = */ this.state.studentEmail,
      /* parent_password = */ this.state.parentVerificationCode.trim(),
      /* student_password = */ this.state.studentVerificationCode.trim(),
      /* full_name = */ this.state.fullName,
      /* address_by = */ this.state.addressBy,
      /* timezone = */ this.state.timezone,
    );

    // console.log(create_account_ok);

    if (create_account_ok === null) {
      // console.log("Created Account");
      this.setState({ accountCreated: true });
    } else {
      this.setState({ serverError: true });
      return;
    }

    // Login
    var name, access_token, error_text;
    [name, access_token, error_text] = await fetchLoginInfo(
      this.state.studentEmail,
      this.state.studentVerificationCode,
    );

    if (access_token === null) {
      this.setState({ serverError: true });
      return;
    }

    var fail = false;
    try {
      // Cache in session storage.
      sessionStorage.setItem("auth_token", access_token);
      sessionStorage.setItem("name", name);
    } catch (err) {
      fail = true;
    }

    if (fail) {
      try {
        sessionStorage.clear();
      } catch (err) {
      }
      this.setState({ clientError: true });
      return;
    }

    this.setState({ loginCompleted: true });
  }

  renderTimezoneOption(label, value) {
    return (
      <option key={label} value={value}>
        {label}
      </option>
    );
  }

  renderDropDown(title, subtitle, change_handler) {
    var tz_strings = [
        {"label":"(GMT-12:00) International Date Line West","value":"Etc/GMT+12"},
        {"label":"(GMT-11:00) Midway Island, Samoa","value":"Pacific/Midway"},
        {"label":"(GMT-10:00) Hawaii","value":"Pacific/Honolulu"},
        {"label":"(GMT-09:00) Alaska","value":"US/Alaska"},
        {"label":"(GMT-08:00) Pacific Time (US & Canada)","value":"America/Los_Angeles"},
        {"label":"(GMT-08:00) Tijuana, Baja California","value":"America/Tijuana"},
        {"label":"(GMT-07:00) Arizona","value":"US/Arizona"},
        {"label":"(GMT-07:00) Chihuahua, La Paz, Mazatlan","value":"America/Chihuahua"},
        {"label":"(GMT-07:00) Mountain Time (US & Canada)","value":"US/Mountain"},
        {"label":"(GMT-06:00) Central America","value":"America/Managua"},
        {"label":"(GMT-06:00) Central Time (US & Canada)","value":"US/Central"},
        {"label":"(GMT-06:00) Guadalajara, Mexico City, Monterrey","value":"America/Mexico_City"},
        {"label":"(GMT-06:00) Saskatchewan","value":"Canada/Saskatchewan"},
        {"label":"(GMT-05:00) Bogota, Lima, Quito, Rio Branco","value":"America/Bogota"},
        {"label":"(GMT-05:00) Eastern Time (US & Canada)","value":"US/Eastern"},
        {"label":"(GMT-05:00) Indiana (East)","value":"US/East-Indiana"},
        {"label":"(GMT-04:00) Atlantic Time (Canada)","value":"Canada/Atlantic"},
        {"label":"(GMT-04:00) Caracas, La Paz","value":"America/Caracas"},
        {"label":"(GMT-04:00) Manaus","value":"America/Manaus"},
        {"label":"(GMT-04:00) Santiago","value":"America/Santiago"},
        {"label":"(GMT-03:30) Newfoundland","value":"Canada/Newfoundland"},
        {"label":"(GMT-03:00) Brasilia","value":"America/Sao_Paulo"},
        {"label":"(GMT-03:00) Buenos Aires, Georgetown","value":"America/Argentina/Buenos_Aires"},
        {"label":"(GMT-03:00) Greenland","value":"America/Godthab"},
        {"label":"(GMT-03:00) Montevideo","value":"America/Montevideo"},
        {"label":"(GMT-02:00) Mid-Atlantic","value":"America/Noronha"},
        {"label":"(GMT-01:00) Cape Verde Is.","value":"Atlantic/Cape_Verde"},
        {"label":"(GMT-01:00) Azores","value":"Atlantic/Azores"},
        {"label":"(GMT+00:00) Casablanca, Monrovia, Reykjavik","value":"Africa/Casablanca"},
        {"label":"(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London","value":"Etc/Greenwich"},
        {"label":"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna","value":"Europe/Amsterdam"},
        {"label":"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague","value":"Europe/Belgrade"},
        {"label":"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris","value":"Europe/Brussels"},
        {"label":"(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb","value":"Europe/Sarajevo"},
        {"label":"(GMT+01:00) West Central Africa","value":"Africa/Lagos"},
        {"label":"(GMT+02:00) Amman","value":"Asia/Amman"},
        {"label":"(GMT+02:00) Athens, Bucharest, Istanbul","value":"Europe/Athens"},
        {"label":"(GMT+02:00) Beirut","value":"Asia/Beirut"},
        {"label":"(GMT+02:00) Cairo","value":"Africa/Cairo"},
        {"label":"(GMT+02:00) Harare, Pretoria","value":"Africa/Harare"},
        {"label":"(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius","value":"Europe/Helsinki"},
        {"label":"(GMT+02:00) Jerusalem","value":"Asia/Jerusalem"},
        {"label":"(GMT+02:00) Minsk","value":"Europe/Minsk"},
        {"label":"(GMT+02:00) Windhoek","value":"Africa/Windhoek"},
        {"label":"(GMT+03:00) Kuwait, Riyadh, Baghdad","value":"Asia/Kuwait"},
        {"label":"(GMT+03:00) Moscow, St. Petersburg, Volgograd","value":"Europe/Moscow"},
        {"label":"(GMT+03:00) Nairobi","value":"Africa/Nairobi"},
        {"label":"(GMT+03:00) Tbilisi","value":"Asia/Tbilisi"},
        {"label":"(GMT+03:30) Tehran","value":"Asia/Tehran"},
        {"label":"(GMT+04:00) Abu Dhabi, Muscat","value":"Asia/Muscat"},
        {"label":"(GMT+04:00) Baku","value":"Asia/Baku"},
        {"label":"(GMT+04:00) Yerevan","value":"Asia/Yerevan"},
        {"label":"(GMT+04:30) Kabul","value":"Asia/Kabul"},
        {"label":"(GMT+05:00) Yekaterinburg","value":"Asia/Yekaterinburg"},
        {"label":"(GMT+05:00) Islamabad, Karachi, Tashkent","value":"Asia/Karachi"},
        {"label":"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi","value":"Asia/Calcutta"},
        {"label":"(GMT+05:30) Sri Jayawardenapura","value":"Asia/Calcutta"},
        {"label":"(GMT+05:45) Kathmandu","value":"Asia/Katmandu"},
        {"label":"(GMT+06:00) Almaty, Novosibirsk","value":"Asia/Almaty"},
        {"label":"(GMT+06:00) Astana, Dhaka","value":"Asia/Dhaka"},
        {"label":"(GMT+06:30) Yangon (Rangoon)","value":"Asia/Rangoon"},
        {"label":"(GMT+07:00) Bangkok, Hanoi, Jakarta","value":"Asia/Bangkok"},
        {"label":"(GMT+07:00) Krasnoyarsk","value":"Asia/Krasnoyarsk"},
        {"label":"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi","value":"Asia/Hong_Kong"},
        {"label":"(GMT+08:00) Kuala Lumpur, Singapore","value":"Asia/Kuala_Lumpur"},
        {"label":"(GMT+08:00) Irkutsk, Ulaan Bataar","value":"Asia/Irkutsk"},
        {"label":"(GMT+08:00) Perth","value":"Australia/Perth"},
        {"label":"(GMT+08:00) Taipei","value":"Asia/Taipei"},
        {"label":"(GMT+09:00) Osaka, Sapporo, Tokyo","value":"Asia/Tokyo"},
        {"label":"(GMT+09:00) Seoul","value":"Asia/Seoul"},
        {"label":"(GMT+09:00) Yakutsk","value":"Asia/Yakutsk"},
        {"label":"(GMT+09:30) Adelaide","value":"Australia/Adelaide"},
        {"label":"(GMT+09:30) Darwin","value":"Australia/Darwin"},
        {"label":"(GMT+10:00) Brisbane","value":"Australia/Brisbane"},
        {"label":"(GMT+10:00) Canberra, Melbourne, Sydney","value":"Australia/Canberra"},
        {"label":"(GMT+10:00) Hobart","value":"Australia/Hobart"},
        {"label":"(GMT+10:00) Guam, Port Moresby","value":"Pacific/Guam"},
        {"label":"(GMT+10:00) Vladivostok","value":"Asia/Vladivostok"},
        {"label":"(GMT+11:00) Magadan, Solomon Is., New Caledonia","value":"Asia/Magadan"},
        {"label":"(GMT+12:00) Auckland, Wellington","value":"Pacific/Auckland"},
        {"label":"(GMT+12:00) Fiji, Kamchatka, Marshall Is.","value":"Pacific/Fiji"},
        {"label":"(GMT+13:00) Nuku'alofa","value":"Pacific/Tongatapu"}
    ];

    var tz_ints = [
        {"label":"(GMT-12:00) International Date Line West","value":"-12"},
        {"label":"(GMT-11:00) Midway Island, Samoa","value":"-11"},
        {"label":"(GMT-10:00) Hawaii","value":"-10"},
        {"label":"(GMT-09:00) Alaska","value":"-9"},
        {"label":"(GMT-08:00) Pacific Time (US & Canada)","value":"-8"},
        {"label":"(GMT-08:00) Tijuana, Baja California","value":"-8"},
        {"label":"(GMT-07:00) Arizona","value":"-7"},
        {"label":"(GMT-07:00) Chihuahua, La Paz, Mazatlan","value":"-7"},
        {"label":"(GMT-07:00) Mountain Time (US & Canada)","value":"-7"},
        {"label":"(GMT-06:00) Central America","value":"-6"},
        {"label":"(GMT-06:00) Central Time (US & Canada)","value":"-6"},
        {"label":"(GMT-05:00) Bogota, Lima, Quito, Rio Branco","value":"-5"},
        {"label":"(GMT-05:00) Eastern Time (US & Canada)","value":"-5"},
        {"label":"(GMT-05:00) Indiana (East)","value":"-5"},
        {"label":"(GMT-04:00) Atlantic Time (Canada)","value":"-4"},
        {"label":"(GMT-04:00) Caracas, La Paz","value":"-4"},
        {"label":"(GMT-04:00) Manaus","value":"-4"},
        {"label":"(GMT-04:00) Santiago","value":"-4"},
        {"label":"(GMT-03:30) Newfoundland","value":"-3.5"},
        {"label":"(GMT-03:00) Brasilia","value":"-3"},
        {"label":"(GMT-03:00) Buenos Aires, Georgetown","value":"-3"},
        {"label":"(GMT-03:00) Greenland","value":"-3"},
        {"label":"(GMT-03:00) Montevideo","value":"-3"},
        {"label":"(GMT-02:00) Mid-Atlantic","value":"-2"},
        {"label":"(GMT-01:00) Cape Verde Is.","value":"-1"},
        {"label":"(GMT-01:00) Azores","value":"-1"},
        {"label":"(GMT+00:00) Casablanca, Monrovia, Reykjavik","value":"0"},
        {"label":"(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London","value":"0"},
        {"label":"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna","value":"1"},
        {"label":"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague","value":"1"},
        {"label":"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris","value":"1"},
        {"label":"(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb","value":"1"},
        {"label":"(GMT+01:00) West Central Africa","value":"1"},
        {"label":"(GMT+02:00) Amman","value":"2"},
        {"label":"(GMT+02:00) Athens, Bucharest, Istanbul","value":"2"},
        {"label":"(GMT+02:00) Beirut","value":"2"},
        {"label":"(GMT+02:00) Cairo","value":"2"},
        {"label":"(GMT+02:00) Harare, Pretoria","value":"2"},
        {"label":"(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius","value":"2"},
        {"label":"(GMT+02:00) Jerusalem","value":"2"},
        {"label":"(GMT+02:00) Minsk","value":"2"},
        {"label":"(GMT+02:00) Windhoek","value":"2"},
        {"label":"(GMT+03:00) Kuwait, Riyadh, Baghdad","value":"3"},
        {"label":"(GMT+03:00) Moscow, St. Petersburg, Volgograd","value":"3"},
        {"label":"(GMT+03:00) Nairobi","value":"3"},
        {"label":"(GMT+03:00) Tbilisi","value":"3"},
        {"label":"(GMT+03:30) Tehran","value":"3.5"},
        {"label":"(GMT+04:00) Abu Dhabi, Muscat","value":"4"},
        {"label":"(GMT+04:00) Baku","value":"4"},
        {"label":"(GMT+04:00) Yerevan","value":"4"},
        {"label":"(GMT+04:30) Kabul","value":"4.5"},
        {"label":"(GMT+05:00) Yekaterinburg","value":"5"},
        {"label":"(GMT+05:00) Islamabad, Karachi, Tashkent","value":"5"},
        {"label":"(GMT+05:30) Sri Jayawardenapura","value":"5.5"},
        {"label":"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi","value":"5.5"},
        {"label":"(GMT+05:45) Kathmandu","value":"5.75"},
        {"label":"(GMT+06:00) Almaty, Novosibirsk","value":"6"},{"label":"(GMT+06:00) Astana, Dhaka","value":"6"},
        {"label":"(GMT+06:30) Yangon (Rangoon)","value":"6.5"},
        {"label":"(GMT+07:00) Bangkok, Hanoi, Jakarta","value":"7"},
        {"label":"(GMT+07:00) Krasnoyarsk","value":"7"},
        {"label":"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi","value":"8"},
        {"label":"(GMT+08:00) Kuala Lumpur, Singapore","value":"8"},
        {"label":"(GMT+08:00) Irkutsk, Ulaan Bataar","value":"8"},
        {"label":"(GMT+08:00) Perth","value":"8"},
        {"label":"(GMT+08:00) Taipei","value":"8"},
        {"label":"(GMT+09:00) Osaka, Sapporo, Tokyo","value":"9"},
        {"label":"(GMT+09:00) Seoul","value":"9"},
        {"label":"(GMT+09:00) Yakutsk","value":"9"},
        {"label":"(GMT+09:30) Adelaide","value":"9.5"},
        {"label":"(GMT+09:30) Darwin","value":"9.5"},
        {"label":"(GMT+10:00) Brisbane","value":"10"},
        {"label":"(GMT+10:00) Canberra, Melbourne, Sydney","value":"10"},
        {"label":"(GMT+10:00) Hobart","value":"10"},
        {"label":"(GMT+10:00) Guam, Port Moresby","value":"10"},
        {"label":"(GMT+10:00) Vladivostok","value":"10"},
        {"label":"(GMT+11:00) Magadan, Solomon Is., New Caledonia","value":"11"},
        {"label":"(GMT+12:00) Auckland, Wellington","value":"12"},
        {"label":"(GMT+12:00) Fiji, Kamchatka, Marshall Is.","value":"12"},
        {"label":"(GMT+13:00) Nuku'alofa","value":"13"}
    ];

    var options = [];
    for(var ii = 0; ii < tz_strings.length; ii++) {
      var tz = tz_strings[ii];
      options.push(this.renderTimezoneOption(tz.label, tz.value));
    }

    return (
      <React.Fragment>
        <Typography variant="body1" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body2">
          {"(" + subtitle + ")"}
        </Typography>
        <FormControl sx={{ m: 1 }}>
          <Select
            native
            label=""
            value={this.state.timezone}
            onChange={change_handler}>
            <option key={"Default"} value="">Select Timezone</option>
            {options}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }

  renderInputField(
    title, 
    subtitle, 
    change_handler, 
    val,
    error_lambda) {
    var error_str = "";

    if (error_lambda !== null) {
      error_str = error_lambda(val);
    }

    return (
      <React.Fragment>
        <Typography variant="body1" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body2">
          {"(" + subtitle + ")"}
        </Typography>
        <TextField
          type="text"
          sx={{ p: 1 }}
          autoComplete="off"
          inputProps={{ 
            spellCheck: "false",
            autoComplete: "off",
            autoCorrect: "off",
            autoCapitalize: "none",
          }}
          size="small"
          value={val}
          error={(error_str !== "")}
          helperText={error_str}
          onChange={change_handler}
          fullWidth={true} />
      </React.Fragment>
    );
  }

  renderStep1() {
    return ( 
      <Stack alignItems="left" spacing={1} sx={{ paddingBottom: 3 }} >
        {this.renderInputField(
          "Enter email address that will be used to login:",
          "You can use a parent's email for students 13 years or younger",
          this.onStudentEmailChange,
          this.state.studentEmail,
          (x) => this.getStudentEmailErrorString())
        }
        {this.renderInputField(
          "Enter parent's or legal guardian's email address:",
          "You can use the student's email if the student is an adult",
          this.onParentEmailChange,
          this.state.parentEmail,
          (x) => this.getParentEmailErrorString())
        }
        {this.renderInputField(
          "Enter student's full name:",
          "Example: Joseph Smith",
          this.onFullNameChange,
          this.state.fullName,
          null, "")
        }
        {this.renderInputField(
          "Enter name to address student by:",
          "Example: Joe",
          this.onAddressByChange,
          this.state.addressBy,
          null, "")
        }
        {this.renderDropDown(
          "Enter your timezone:",
          "used to determine your daily review set",
          this.onTimezoneChange,
          this.state.timezone)
        }
      </Stack>
    );
  }

  renderStep1Button() {
    const is_disabled = (
      // Student email checks.
      this.state.studentEmail.trim() === "" ||
      !this.isValidEmail(this.state.studentEmail.trim()) ||
      this.state.studentEmailExists ||

      // Parent email checks.
      this.state.parentEmail.trim() === "" ||
      !this.isValidEmail(this.state.parentEmail.trim()) ||

      // full name should be non-empty
      this.state.fullName.trim() === "" ||

      // Address-by should be non-empty
      this.state.addressBy.trim() === "" ||

      // Timezone should be populated.
      this.state.timezone.trim() === "" ||
      
      // This button has already been pressed
      this.state.parentVerificationSent === true
    );

    const show_disabled_text = (
      (this.state.studentEmail.trim() !== "" && 
       !this.isValidEmail(this.state.studentEmail.trim()))
      ||
      this.state.studentEmailExists
      ||
      (this.state.parentEmail.trim() !== "" &&
       !this.isValidEmail(this.state.parentEmail.trim()))
    );

    var message_elem;
    if (!is_disabled && this.state.serverError) {
      message_elem = (
        <Typography variant="subtitle1" color="red">
          Our system has a problem. Please try again later.
        </Typography>
      );
    } else if (show_disabled_text) {
      message_elem = (
        <Typography variant="subtitle1" color="red">
          Please fix errors above before proceeding.
        </Typography>
      );
    } else {
      message_elem = (
        <Typography variant="subtitle1">
          {" "}
        </Typography>
      );
    }

    const button_elem = (
      <Button
        variant="contained"
        onClick={this.onStep1ButtonClick}
        disabled={is_disabled}>
        Continue to Step 2
      </Button>
    );

    return (
      <Stack spacing={1} sx={{ paddingBottom: 3}}>
        {message_elem}
        {button_elem}
      </Stack>
    );
  }

  renderStep2() {
    if (!this.state.parentVerificationSent) { return null; }

    return (
      <Stack spacing={1} sx={{ paddingBottom: 3 }} >
        {this.renderInputField(
          "Enter verification code sent to parent's email:",
          "The code will be four 4-digit numbers separated by dashes",
          this.onParentVerificationCodeChange,
          this.state.parentVerificationCode,
          (x) => this.getParentVerificationCodeErrorString())
        }
      </Stack>
    );
  }

  renderStep2Button() {
    if (!this.state.parentVerificationSent) { return null; }

    const is_disabled = (
      this.state.parentVerificationCode.trim() === "" ||
      !this.isValidVerificationCode(this.state.parentVerificationCode) ||
      this.state.parentVerificationCodeInvalid ||
      this.state.studentVerificationSent
    ); 

    const show_disabled_message = (
      (this.state.parentVerificationCode.trim() !== "" &&
       !this.isValidVerificationCode(this.state.parentVerificationCode))
      ||
      this.state.parentVerificationCodeInvalid
    );

    var message_elem;
    if (!is_disabled && this.state.serverError) {
      message_elem = (
        <Typography variant="subtitle1" color="red">
          Our system has a problem. Please try again later.
        </Typography>
      );
    } else if (show_disabled_message) {
      message_elem = (
        <Typography variant="subtitle1" color="red">
          Please fix errors above before proceeding.
        </Typography>
      );
    } else {
      message_elem = (
        <Typography variant="subtitle1">
          {" "}
        </Typography>
      );
    }

    const button_elem = (
      <Button
        variant="contained"
        onClick={this.onStep2ButtonClick}
        disabled={is_disabled}>
        Verify Parent Code
      </Button>
    );

    return (
      <Stack spacing={1} sx={{ paddingBottom: 3}}>
        {message_elem}
        {button_elem}
      </Stack>
    );
  }

  renderStep3() {
    if (!this.state.studentVerificationSent) { return null; }

    return (
      <Stack alignItems="left" spacing={1} sx={{ paddingBottom: 3 }} >
        {this.renderInputField(
          "Enter verification code sent to student's email:",
          "The code will be four 4-digit numbers separated by dashes",
          this.onStudentVerificationCodeChange,
          this.state.studentVerificationCode,
          (x) => this.getStudentVerificationCodeErrorString())
        }
      </Stack>
    );
  }

  renderStep3Button() {
    if (!this.state.studentVerificationSent) { return null; }

    const is_disabled = (
      this.state.studentVerificationCode.trim() === "" ||
      !this.isValidVerificationCode(this.state.studentVerificationCode) ||
      this.state.studentVerificationCodeInvalid
    ); 

    const show_disabled_message = (
      (this.state.studentVerificationCode.trim() !== "" &&
       !this.isValidVerificationCode(this.state.studentVerificationCode))
      ||
      this.state.studentVerificationCodeInvalid
    ); 

    var message_elem;
    if (!is_disabled && this.state.serverError) {
      message_elem = (
        <Typography variant="subtitle1" color="red">
          Our system has a problem. Please try again later.
        </Typography>
      );
    } else if (this.state.clientError) {
      message_elem = (
        <Typography variant="subtitle1" color="red">
          Your browser has session storage disabled. Please 
          use another browser, or change your settings
        </Typography>
      );
    } else if (show_disabled_message) {
      message_elem = (
        <Typography variant="subtitle1" color="red">
          Please fix errors above before proceeding.
        </Typography>
      );
    } else {
      message_elem = (
        <Typography variant="subtitle1">
          {" "}
        </Typography>
      );
    }

    const button_elem = (
      <Button
        variant="contained"
        onClick={this.onStep3ButtonClick}
        disabled={is_disabled}>
        Verify Student Code & Create Account
      </Button>
    );

    return (
      <Stack spacing={1} sx={{ paddingBottom: 3}}>
        {message_elem}
        {button_elem}
      </Stack>
    );
  }
  render() {
    if (this.state.loginCompleted === true) {
      return (
        <Navigate to="/" replace={true} />
      );
    }

    return (
      <Stack alignItems="center" sx={{ paddingBottom: 5}} >
        <SpellableLogo />

        {this.renderStep1()}
        {this.renderStep1Button()}
        {this.renderStep2()}
        {this.renderStep2Button()}
        {this.renderStep3()}
        {this.renderStep3Button()}
      </Stack>
    );
  }
}

