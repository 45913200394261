import React from 'react';
import { connect } from 'react-redux';
import { submitAnswer } from './actions.js';

import SpellableButton from './SpellableButton.js';

class SubmitButton extends React.Component {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.onClick = this.onClick.bind(this);
  }

  shouldDisable() {
    return this.props.answerGiven || (this.props.chosenOption === -1);
  }

  componentDidMount() {
    if (!this.shouldDisable()) {
      this.buttonRef.current.focus();
    }
  }

  componentDidUpdate(prev_props) {
    if (!this.shouldDisable()) {
      this.buttonRef.current.focus();
    }
  }

  onClick(e) {
    this.props.submitAnswer();
  }

  render() {
    return (
      <SpellableButton
        ref={this.buttonRef}
        variant="outlined"
        onClick={this.onClick}
        disabled={this.shouldDisable()}>
        Submit Answer
      </SpellableButton>
    );
  }
}

function mapStateToProps(state) {
  return {
    chosenOption: state.currentCardState.answerState.chosenOption,
    answerGiven: state.currentCardState.answerState.answerGiven,
  };
}

const mapDispatchToProps = {
  submitAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitButton);
