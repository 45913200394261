import React from 'react';

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default class NestedListOfWords extends React.Component {
  render() {
    const words = Object.keys(this.props.mapOfWordLists).sort();

    // Render nothing, not even the title, if the word-list is empty.
    if (words.length === 0) {
      return (
        <Stack alignItems="center" />
      );
    }

    var word_list_divs = [];

    for (var ii = 0; ii < words.length; ii++) {
      const word = words[ii];

      // If the array hanging off the key is null, we skip this.
      if (this.props.mapOfWordLists[word] === null) { continue; }

      const word_url = 
        "https://www.merriam-webster.com/dictionary/" + word + "#etymology";

      word_list_divs.push(
        <ListItem 
          disableGutters
          sx={{ justifyContent: 'center' }}
          key={word + "_"}>
          <Link 
            href={word_url} 
            target="_blank" 
            rel="noopener noreferrer">
            {word}
          </Link>
        </ListItem>
      );

      // Render the nested list
      for(var jj in this.props.mapOfWordLists[word]) {
        word_list_divs.push(
          <ListItem 
            disableGutters
            key={word + "_" + jj}
            sx={{ color: 'text.secondary', justifyContent: 'center' }}>
            {"(" + this.props.mapOfWordLists[word][jj] + ")"}
          </ListItem>
        );
      }
    }

    return (
      <Stack alignItems="center" sx={{ mt: 2 }}>
        <Typography 
          sx={{ fontWeight: 'bold' }}
          variant="subtitle2">
          {this.props.title}
        </Typography>
        <List dense>
          {word_list_divs}
        </List>
      </Stack>
    );
  }
} 
