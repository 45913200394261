import React from 'react';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import SpellableButton from './SpellableButton.js';

const ERROR_WORDS = {
  "malfunction": "(noun, verb) Fail to perform normally.",
  "collapse": "(noun, verb) Fall or shrink together abruptly or completely.",
  "founder": "(verb) To become disabled; to give way.",
  "misfire": "(noun, verb) A failure to fire; to miss an intended objective.",
  "flatline": "(verb) To register on an electronic monitor as having no brain waves or heartbeat; to die.",
  "blunder": "(noun, verb) A clumsy or embarrassing mistake.",
  "fallacy": "(noun) Deceptive or false appearance; that which misleads the eye or mind.",
  "boo-boo": "(noun) A mistake or error, often childish.",
  "howler": "(noun) A painfully obvious mistake.",
  "bloomer": "(noun) A large or embarrassing mistake.",
};

class ErrorScreen extends React.Component {
  renderDefinition() {
    const keys = Object.keys(ERROR_WORDS);
    const word = keys[Math.floor(keys.length * Math.random())];
    const meaning = ERROR_WORDS[word];

    return (
      <Stack 
        alignItems="center"
        justifyContent="center"
        sx={{ 
          borderTop: 1,
          borderBottom: 1,
          mt: 4,
          color: "gray",
          borderColor: "text.disabled",
          padding: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {word}
        </Typography>
        <Typography variant="body1">
          {meaning}
        </Typography>
      </Stack>
    );
  }

 
  renderButton() {
    var button_text = "Retry";

    if (this.props.buttonText) {
      button_text = this.props.buttonText;
    }

    return(
      <Stack alignItems="center" spacing={1}>
        <Typography variant="subtitle">
          Please click the button below to retry.
        </Typography>
        <SpellableButton
          variant="outlined"
          onClick={this.props.retryHandler}>
          {button_text}
        </SpellableButton>
      </Stack>
    );
  }

  render() {
    const error_url = "https://s3.amazonaws.com/thousandwords/exclamation.png";
    return (
      <Stack 
        spacing={2}
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          mt: 9 }}>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="img"
            sx={{ width: 20 }}
            src={error_url}
            alt=""
          />

          <Typography variant="h6">
            {this.props.message}
          </Typography>
        </Stack>
        {this.renderButton()}
        {this.renderDefinition()}
        {this.props.children}
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorScreen);

