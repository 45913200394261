import React from 'react';
import { connect } from 'react-redux';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { 
  updateDarkMode,
} from './actions.js';

class DarkModeSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    // const dark_mode = sessionStorage.getItem('dark_mode');
    // this.props.updateDarkMode(dark_mode === true);
  }

  handleChange(e) {
    // sessionStorage.setItem("dark_mode", access_token);
    this.props.updateDarkMode(e.target.checked);
  }

  render() {
    const switch_element = (
      <Switch
        checked={this.props.darkMode}
        onChange={this.handleChange} />
    );

    const label_element = (
      <Typography variant="body2">
        Enable Dark Mode
      </Typography>
    );
      
    return (
      <FormGroup sx={{marginTop: 10}} >
        <FormControlLabel
          control={switch_element}
          label={label_element} />
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    darkMode: state.darkModeState,
  };
}

const mapDispatchToProps = {
  updateDarkMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(DarkModeSwitch);
