import { 
  fetchPreLoginInfo,
  fetchLoginInfo,
  fetchLists,
  fetchWords,
  fetchSummary,
  uploadWords,
} from './graphql.js';

export const UPDATE_DARK_MODE = "UPDATE_DARK_MODE";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const PRE_LOGIN_BEGIN = "PRE_LOGIN_BEGIN";
export const PRE_LOGIN_SUCCESS = "PRE_LOGIN_SUCCESS";
export const PRE_LOGIN_FAILURE = "PRE_LOGIN_FAILURE";
export const UPDATE_PASSPHRASE = "UPDATE_PASSPHRASE";
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const FAST_LOGIN = "FAST_LOGIN";
export const PREFETCH_LANDING_STATE_BEGIN = "PREFETCH_LANDING_STATE_BEGIN";
export const PREFETCH_LANDING_STATE_FAILURE = "PREFETCH_LANDING_STATE_FAILURE";
export const PREFETCH_LANDING_STATE_SUCCESS = "PREFETCH_LANDING_STATE_SUCCESS";
export const PREFETCH_WORDS_BEGIN = "PREFETCH_WORDS_BEGIN";
export const PREFETCH_WORDS_SUCCESS = "PREFETCH_WORDS_SUCCESS";
export const PREFETCH_WORDS_FAILURE = "PREFETCH_WORDS_FAILURE";
export const PREFETCH_WORDS_RESET = "PREFETCH_WORDS_RESET";
export const LOG_REVIEWED_WORDS_BEGIN = "LOG_REVIEWED_WORDS_BEGIN";
export const LOG_REVIEWED_WORDS_SUCCESS = "LOG_REVIEWED_WORDS_SUCCESS";
export const LOG_REVIEWED_WORDS_FAILURE = "LOG_REVIEWED_WORDS_FAILURE";
export const NEXT_WORD_REQUESTED = "NEXT_WORD_REQUESTED";
export const UPDATE_ROOT = "UPDATE_ROOT";
export const SUBMIT_ROOT = "SUBMIT_ROOT";
export const UPDATE_ANSWER = "UPDATE_ANSWER";
export const SUBMIT_ANSWER = "SUBMIT_ANSWER";
export const PROBLEM_REPORTED = "PROBLEM_REPORTED";
export const SET_AUDIO_STATE = "SET_AUDIO_STATE";

export function preLogin(email) {
  return async function (dispatch) {
    dispatch(preLoginBegin(email));

    const error_text = await fetchPreLoginInfo(email);
    // console.log(error_text);
    if (error_text !== null) {
      dispatch(preLoginFailure(error_text));
      return;
    }

    dispatch(preLoginSuccess());
  }
}

export function login(email, passphrase) {
  return async function (dispatch) {
    var name, access_token, error_text;

    dispatch(loginBegin(passphrase));

    [name, access_token, error_text] = await fetchLoginInfo(email, passphrase);
    if (access_token === null) {
      dispatch(loginFailure(error_text));
      return;
    }

    dispatch(loginSuccess(access_token, name)); 
  }
}

export function prefetchLandingState(access_token, concept_type) {
  return async function (dispatch) {
    var lists, summaries, error_text;
    var cur_list, cur_summary;

    lists = [null, null, null];
    summaries = [null, null, null];

    dispatch(prefetchLandingStateBegin());

    if (concept_type != null) {
      var responses = await Promise.allSettled([
        fetchLists(access_token, concept_type),
        fetchSummary(access_token, concept_type),
      ]);

      responses = Array.from(responses);
    
      [cur_list, error_text] = responses[0].value;
      if (cur_list === null) {
        dispatch(prefetchLandingStateFailure(error_text));
        return;
      }

      [cur_summary, error_text] = responses[1].value;
      if (cur_summary === null) {
        dispatch(prefetchLandingStateFailure(error_text));
        return;
      }

      if (concept_type === "SPELLING") {
        lists[0] = cur_list;
        summaries[0] = cur_summary;
      } else if (concept_type === "VOCAB") {
        lists[1] = cur_list;
        summaries[1] = cur_summary;
      } else {
        lists[2] = cur_list;
        summaries[2] = cur_summary;
      }

      dispatch(prefetchLandingStateSuccess(lists, summaries))
      return;
    }
 
    // HACK: The order of the fetches must be SPELLING, VOCAB, ROOT
    // to match the order of ConceptType enum.
    responses = await Promise.allSettled([
      fetchLists(access_token, "SPELLING"),
      fetchLists(access_token, "VOCAB"),
      fetchLists(access_token, "ROOTS"),
      fetchSummary(access_token, "SPELLING"),
      fetchSummary(access_token, "VOCAB"),
      fetchSummary(access_token, "ROOTS"),
    ]);

    responses = Array.from(responses);

    for (var ii = 0; ii < 3; ii++) {
      [cur_list, error_text] = responses[ii].value;

      if (cur_list === null) {
        dispatch(prefetchLandingStateFailure(error_text));
        return;
      }

      [cur_summary, error_text] = responses[ii + 3].value;

      if (cur_summary === null) {
        dispatch(prefetchLandingStateFailure(error_text));
        return;
      }

      lists[ii] = cur_list
      summaries[ii] = cur_summary;
    }
   
    dispatch(prefetchLandingStateSuccess(lists, summaries))
  }
}

export function prefetchWords(
  access_token, 
  list_id, 
  sub_list_handle,
  review_mode,
  start_index,
  length
) {
  return async function (dispatch) {
    var words, error_text;

    dispatch(prefetchWordsBegin());

    [words, error_text] = await fetchWords(
      access_token, 
      list_id, 
      sub_list_handle,
      review_mode,
      start_index,
      length,
    );

    if (words === null || words.length === 0) {
      dispatch(prefetchWordsFailure(error_text));
      return;
    }

    dispatch(
      prefetchWordsSuccess(list_id, sub_list_handle, review_mode, words)
    );
  }
}

export function logReviewedWords(
  access_token,
  list_id,
  sub_list_handle,
  review_mode,
  concept_type,
  total_count,
  correct_count, 
  duration_in_ms,
  mastered,
  unsure,
  incorrect,
  roots,
  problems,
  voice_indices,
) {
  return async function (dispatch) {
    var ok, error_text;

    dispatch(logReviewedWordsBegin());

    [ok, error_text] = await uploadWords(
                        access_token,
                        list_id,
                        sub_list_handle,
                        review_mode,
                        concept_type,
                        total_count,
                        correct_count, 
                        duration_in_ms,
                        mastered,
                        unsure,
                        incorrect,
                        roots,
                        problems,
                        voice_indices,
    );

    if (ok === false) {
      dispatch(logReviewedWordsFailure(error_text));
      return;
    }

    dispatch(logReviewedWordsSuccess());
  }
}

export function logReviewedWordsAndPrefetchLandingState(
  access_token,
  list_id,
  sub_list_handle,
  review_mode,
  concept_type,
  total_count,
  correct_count, 
  duration_in_ms,
  mastered,
  unsure,
  incorrect,
  roots,
  problems,
  voice_indices,
) {
  return async function (dispatch) {
    var disp_method = logReviewedWords(
                        access_token,
                        list_id,
                        sub_list_handle,
                        review_mode,
                        concept_type,
                        total_count,
                        correct_count, 
                        duration_in_ms,
                        mastered,
                        unsure,
                        incorrect,
                        roots,
                        problems,
                        voice_indices,
                      );

    await disp_method(dispatch);

    disp_method = prefetchLandingState(access_token, concept_type);
    await disp_method(dispatch);
  }
}

export function updateDarkMode(dark_mode) {
  return {
    type: UPDATE_DARK_MODE,
    darkMode: dark_mode,
  };
}

export function updateEmail(email) {
  return {
    type: UPDATE_EMAIL,
    email: email,
  };
}
  
export function preLoginBegin(email) {
  return {
    type: PRE_LOGIN_BEGIN,
    email: email,
  };
}

export function preLoginSuccess() {
  return {
    type: PRE_LOGIN_SUCCESS,
  };
}

export function preLoginFailure(error_text) {
  return {
    type: PRE_LOGIN_FAILURE,
    description: error_text,
  };
}

export function updatePassphrase(passphrase) {
  return {
    type: UPDATE_PASSPHRASE,
    passphrase: passphrase,
  };
}
  
export function loginBegin(passphrase) {
  return {
    type: LOGIN_BEGIN,
    passphrase: passphrase,
  };
}

export function loginSuccess(access_token, name) {
  return {
    type: LOGIN_SUCCESS,
    token: access_token,
    name: name,
  };
}

export function loginFailure(error_text) {
  return {
    type: LOGIN_FAILURE,
    description: error_text,
  };
}

export function fastLogin(access_token, name) {
  return {
    type: FAST_LOGIN,
    token: access_token,
    name: name,
  };
}

export function prefetchLandingStateBegin() {
  return {
    type: PREFETCH_LANDING_STATE_BEGIN,
  };
}

export function prefetchLandingStateSuccess(lists, summaries) {
  return {
    type: PREFETCH_LANDING_STATE_SUCCESS,
    lists: lists,
    summaries: summaries,
  };
}

export function prefetchLandingStateFailure(error_text) {
  return {
    type: PREFETCH_LANDING_STATE_FAILURE,
    description: error_text,
  };
}

export function prefetchWordsBegin() {
  return {
    type: PREFETCH_WORDS_BEGIN,
  };
}

export function prefetchWordsSuccess(
  list_id,
  sub_list_handle,
  review_mode,
  words,
) {
  return {
    type: PREFETCH_WORDS_SUCCESS,
    listId: list_id,
    subListHandle: sub_list_handle,
    reviewMode: review_mode,
    words: words,
  };
}

export function prefetchWordsFailure(error_text) {
  return {
    type: PREFETCH_WORDS_FAILURE,
    description: error_text,
  };
}

export function prefetchWordsReset() {
  return {
    type: PREFETCH_WORDS_RESET,
  };
}

export function logReviewedWordsBegin() {
  return {
    type: LOG_REVIEWED_WORDS_BEGIN,
  };
}

export function logReviewedWordsSuccess() {
  return {
    type: LOG_REVIEWED_WORDS_SUCCESS,
  };
}

export function logReviewedWordsFailure(error_text) {
  return {
    type: LOG_REVIEWED_WORDS_FAILURE,
    description: error_text,
  };
}

export function nextWordRequested() {
  return {
    type: NEXT_WORD_REQUESTED,
  };
}

export function updateRoot(entered_root) {
  return {
    type: UPDATE_ROOT,
    enteredRoot: entered_root,
  };
}

export function submitRoot(entered_root) {
  return {
    type: SUBMIT_ROOT,
    enteredRoot: entered_root,
  };
}

export function updateAnswer(entered_answer, chosen_option) {
  return {
    type: UPDATE_ANSWER,
    enteredAnswer: entered_answer,
    chosenOption: chosen_option,
  };
}

export function submitAnswer() {
  return {
    type: SUBMIT_ANSWER,
  };
}

export function problemReported(problem_state) {
  return {
    type: PROBLEM_REPORTED,
    problemState: problem_state,
  };
}

export function setAudioState(auto_play, voice_index) {
  return {
    type: SET_AUDIO_STATE,
    autoPlay: auto_play,
    voiceIndex: voice_index,
  };
}
