import React from 'react';
import { connect } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

class WordMeanings extends React.Component {
  render() {
    var meanings = [];
    var ii;

    if (this.props.answerGiven) {
      for (ii in this.props.meanings) {
        meanings.push(
          <ListItem 
            disableGutters
            sx={{ display: 'list-item' }} 
            key={"meanings_" + ii}>
            {this.props.meanings[ii]}
          </ListItem>
        );
       
      }
    } else {
      for (ii in this.props.meanings) {
        meanings.push(
          <ListItem 
            disableGutters
            sx={{ display: 'list-item' }} 
            key={"meanings_" + ii}>
            {this.props.obfuscatedMeanings[ii]}
          </ListItem>
        );
      }
    }
 
    return (
      <Stack alignItems="left" sx={{ mt: 3 }}>
        <Typography 
          variant="body1" 
          sx={{ fontWeight: 'bold' }}>
          Meaning(s):
        </Typography>
        <List 
          dense 
          sx={{ marginInlineStart: 3 }} 
          style={{ listStyleType: "disc" }}>
          {meanings}
        </List>
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  const word_data = state.conceptState.getWordData();

  return {
    answerGiven: state.currentCardState.answerState.answerGiven,
    meanings: word_data.meanings,
    obfuscatedMeanings: word_data.obfuscatedMeanings,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WordMeanings);
