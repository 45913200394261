const SubListHandle = {
  // Hack for now - copied from the server side.
  DAILY_REVIEW_SUB_LIST_HANDLE: "daily-review-sub-list",
  FREQ_MISS_SUB_LIST_HANDLE: "freq-miss-sub-list",
  INFREQ_REVIEW_SUB_LIST_HANDLE: "infreq-review-sub-list",
  UNREVIEWED_SUB_LIST_HANDLE: "unreviewed-sub-list",
  STANDARD_SUB_LIST_HANDLE: "standard-sub-list",
};


export default SubListHandle;
