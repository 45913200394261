import React from 'react';
import { connect } from 'react-redux';

import Accordion from '@mui/material/Accordion';

class SpellableAccordion extends React.Component {
  render() {
    return (
      <Accordion 
        disableGutters 
        elevation={0}
        expanded={this.props.expanded}
        onChange={this.props.onChange}
        sx={{ 
          border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
          '&:not(:last-child)': { borderBottom: 0 },
          '&:before': { display: 'none' },
        }}>
        {this.props.children}
      </Accordion>
    );
  }
} 

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SpellableAccordion);

