import React from 'react';
import { connect } from 'react-redux';
import { problemReported } from './actions.js';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

class ReportFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const key = e.target.id;
    const problem_state = Object.assign({}, this.props.problemState);
    
    problem_state[key] = e.target.checked;
    // console.log(problem_state);
    this.props.problemReported(problem_state);
  }

  renderCheckboxOption(title) {
    const val = title.toLowerCase();

    const checkbox = (
      <Checkbox
        id={val}
        size="small"
        sx={{ color: "text.disabled" }}
        checked={this.props.problemState[val]}
        onChange={this.onChange} />
    );

    return (
      <FormControlLabel
        key={val}
        control={checkbox}
        label={<Typography variant="body2">{title}</Typography>}
        size="small" />
    );
  }
  
  render() {
    return (
      <Stack alignItems="center" mt={4}>
        <Typography variant="body2" sx={{ color: "text.disabled" }} >
          Report Problem (you can choose multiple):
        </Typography>
        <Stack direction="row" sx={{ color: "text.disabled" }}>
          {this.renderCheckboxOption("Audio")}
          {this.renderCheckboxOption("Root")}
          {this.renderCheckboxOption("Meanings")}
          {this.renderCheckboxOption("Notes")}
        </Stack>
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {
    problemState: state.currentCardState.problemState,
  };
}

const mapDispatchToProps = {
  problemReported,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportFeedback);
