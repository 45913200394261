import React from 'react';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';

import Constants from './Constants.js';

class SpellableLogo extends React.Component {
  render() {
    return (
      <Box sx={{ height: 150, alignItems: "center" }}>
        <img className="login-logo" src={Constants.logoLink} alt="" />
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SpellableLogo);

