import React from 'react';
import { connect } from 'react-redux';

import Stack from '@mui/material/Stack';

import AudioControl from './AudioControl.js';
import NextWordButton from './NextWordButton.js';
import ReportFeedback from './ReportFeedback.js';
import RootInput from './RootInput.js';
import SpellableScore from './SpellableScore.js';
import WordMeanings from './WordMeanings.js';
import WordNotes from './WordNotes.js';
import WordRoot from './WordRoot.js';
import WordSpelling from './WordSpelling.js';

class BrowseCard extends React.Component {

  render() {
    const answer = this.props.wordData.spelling; 
    const alternate_spellings = this.props.wordData.alt;
    const displayed_answer = this.props.wordData.displayableWord;
    const unique_key = this.props.wordData.uniqueKey;
    const notes = this.props.wordData.notes;
    // const examples = [];
    const examples = this.props.wordData.examples;

    const audio_elem = (
      <AudioControl uniqueKey={unique_key} />
    );
    const concept_type = this.props.subListMetadata.getConceptType();
  
    return (
      <Stack alignItems="center">
        <WordSpelling 
          shouldShow={true}
          spelling={displayed_answer} 
          alt={alternate_spellings}
          renderVariantsInTitle={concept_type === "ROOTS"} />

        {(concept_type !== "ROOTS") ? audio_elem : null }

        <WordRoot />

        <RootInput 
          enteredRoot={this.props.enteredRoot} />

        <WordMeanings />

        <WordNotes
          word={answer}
          alt={alternate_spellings}
          examples={examples}
          notes={notes}
          shouldShow={true} />

        <NextWordButton shouldShow={true} />

        <SpellableScore />
  
        <ReportFeedback />

      </Stack>
    );
  }
}

function mapStateToProps(state) {
  const word_data = state.conceptState.getWordData();

  return {
    subListMetadata: state.selectedListState.subListMetadata,
    wordData: word_data,
    enteredAnswer: state.currentCardState.answerState.enteredAnswer,
    enteredRoot: state.currentCardState.enteredRoot,
  };
}

export default connect(mapStateToProps)(BrowseCard);
