import React from 'react';
import { connect } from 'react-redux';
import { nextWordRequested } from './actions.js';

import SpellableButton from './SpellableButton.js';

class NextWordButton extends React.Component {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
  }

  /*
  componentDidMount() {
    if (this.props.shouldShow === true) {
      this.buttonRef.current.focus();
    }
  } 

  componentDidUpdate(prev_props) {
    if (this.props.shouldShow === true) {
      this.buttonRef.current.focus();
    }
  } */

  render() {
    return (
      <SpellableButton
        ref={this.buttonRef}
        variant="outlined"
        onClick={this.props.nextWordRequested}
        disabled={!this.props.shouldShow}>
        Next Word
      </SpellableButton>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  nextWordRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(NextWordButton);

 
