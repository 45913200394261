import React from 'react';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CollapsibleCard from './CollapsibleCard.js';
import ErrorScreen from './ErrorScreen.js';
import ListDetails from './ListDetails.js';
import ListType from './ListType.js';
import NestedListOfWords from './NestedListOfWords.js';
import SubListHandle from './SubListHandle.js';

import { 
  OperationState,
  ConceptType,
} from './index.js';

import { 
  logReviewedWordsAndPrefetchLandingState,
  prefetchLandingState,
  prefetchWordsReset,
} from './actions.js';

class LandingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.uploadThenPrefetch = this.uploadThenPrefetch.bind(this);
    this.resetSelectedListState = this.resetSelectedListState.bind(this);
    this.selectElementRef = React.createRef();
    this.state = { 
      selectedListId: "",
    };
  }

  componentDidMount() {
    this.uploadThenPrefetch();
    this.setState({ selectedListId: "" });
  }

  flatten(map_of_lists) {
    var result = [];
    const words = Object.keys(map_of_lists).sort();
    for (var ii = 0; ii < words.length; ii++) {
      const word = words[ii];
      if (map_of_lists[word] === null) { continue; }

      result.push(word);
      for(var jj in map_of_lists[word]) {
        result.push("(" + map_of_lists[word][jj] + ")");
      }
    }

    return result;
  }

  getSubListFromList(selected_list, sub_list_handle) {
    const filtered_lists = selected_list.subLists.filter(
      x => x['handle'] === sub_list_handle,
    );

    if (filtered_lists.length === 0) {
      return null;
    }

    return filtered_lists[0];
  }
    
  uploadThenPrefetch() {
    if (this.props.conceptCollection === null) {
      this.props.prefetchLandingState(this.props.authToken, null);
      return;
    }

    this.props.logReviewedWordsAndPrefetchLandingState(
      this.props.authToken,
      this.props.listId,
      this.props.subListMetadata.getSubListHandle(),
      this.props.subListMetadata.getReviewMode(),
      this.props.conceptCollection.conceptType,
      this.props.totalAnswers,
      this.props.correctAnswers,
      Date.now() - this.props.startTimeInMillisecs,
      this.flatten(this.props.masteredWords),
      this.flatten(this.props.unsureWords),
      this.flatten(this.props.incorrectlyAnsweredWords),
      this.flatten(this.props.updatedRoots),
      this.flatten(this.props.problemReports),
      this.flatten(this.props.voiceIndices),
    );
  }

  resetSelectedListState() {
    this.props.prefetchWordsReset();
  }

  onChangeHandler(e) {
    this.setState({ selectedListId: e.target.value });
  }

  renderUploadStatus() {
    const upload_url = "https://s3.amazonaws.com/thousandwords/check.png";
    const upload_text = "Progress uploaded successfully!";

    return (
      <Stack direction="row" spacing={1}>
        <Box
          component="img"
          sx={{ height: 18, width: 18 }}
          src={upload_url}
          alt=""
        />
        <Typography variant="subtitle">
          {upload_text}
        </Typography>
      </Stack>
    );
  }

  renderOption(cur_list) {
    const reviewed = cur_list.reviewed;
    const daily_review_sub_list = this.getSubListFromList(
      cur_list,
      SubListHandle.DAILY_REVIEW_SUB_LIST_HANDLE,
    );

    // Not every list has a daily-review component (such as 
    // global freq-miss lists. Handle those here.
    const to_review = (daily_review_sub_list === null) ? 
      0 : daily_review_sub_list.length;
    const mastered = Math.max(reviewed - to_review, 0);

    var suffix;
    if (mastered === 0) {
      suffix = "";
    } else if (mastered === cur_list.listLength) {
      suffix = "(100% mastered)";
    } else {
      var pct = Math.round(mastered * 100 / cur_list.listLength);
      if (pct === 100) { pct = 99; }
      suffix = "(" + pct + "% mastered)";
    }

    const list_id = cur_list.id;
    const list_name = cur_list.name + " " + suffix;
    const disabled = (cur_list.listLength === 0);

    return (
      <option key={list_id} value={list_id} disabled={disabled}>
        {list_name}
      </option>
    );
  }

  renderDropdown() {
    var optgroups = [];
    var options = [];
    var cur_list;

    // Daily reviews go into a separate section also.
    for(var list_id in this.props.listsMetadataById) {
      cur_list = this.props.listsMetadataById[list_id];

      if (cur_list.listType === ListType.DAILY_REVIEW) {
        options.push(this.renderOption(cur_list));
      }
    }

    const section_label = "Daily Review";
    optgroups.push(
      <optgroup key={section_label} label={section_label}>{options}</optgroup>
    );
    

    var labels = ["Spelling", "Vocabulary", "Roots"];

    // console.log(this.props.listsByConceptType);

    for (var ii = 0; ii < 3; ii++) {
      options = [];
      for (var jj in this.props.listsByConceptType[ii]) {
        cur_list = this.props.listsByConceptType[ii][jj];
        options.push(this.renderOption(cur_list));
      }

      optgroups.push(
        <optgroup key={labels[ii]} label={labels[ii]}>{options}</optgroup>
      );
    }

    //      <InputLabel htmlFor="list-select">Select a List</InputLabel>
    return (
      <div>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            native
            label=""
            ref={this.selectElementRef}
            value={this.state.selectedListId}
            onChange={this.onChangeHandler}>
            <option key={"Default"} value="">Select a List</option>
            {optgroups}
          </Select>
        </FormControl>
      </div>
    );
  }

  render() {
    if (this.props.uploadStatus === OperationState.FAILURE) {
      return (
        <ErrorScreen
          message="Your progress could not be saved."
          retryHandler={this.uploadThenPrefetch} />
      );
    }

    if (this.props.prefetchStatus === OperationState.FAILURE) {
      return (
        <ErrorScreen
          message="Lists information could not be retrieved."
          retryHandler={this.uploadThenPrefetch} />
      );
    }

    if (this.props.fetchStatus === OperationState.FAILURE) {
      return (
        <ErrorScreen
          message="The list likely has no words, or could not be retrieved."
          buttonText="Return"
          retryHandler={this.resetSelectedListState} />
      );
    }

    if (this.props.prefetchStatus !== OperationState.SUCCESS ||
        this.props.fetchStatus === OperationState.IN_PROGRESS) {
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress
            size={50} 
            sx={{color: "text.primary"}}
            style={{marginTop: '20%' }} />
        </div>
      );
    }

    const mastered_prefix = 
      ((this.props.subListMetadata !== null) && 
        this.props.subListMetadata.isBrowseMode()) ?
      "You have browsed " : "You have reviewed ";

    const mastered = mastered_prefix +
      this.props.summariesByConceptType[ConceptType.SPELLING - 1].total + " spellings, " +
      this.props.summariesByConceptType[ConceptType.VOCAB - 1].total + " vocabulary words, and " +
      this.props.summariesByConceptType[ConceptType.ROOTS - 1].total + " roots!";

    var details = null;
    var subtext2 = null;

    if (this.props.isStart) {
      subtext2 = (
        <Typography variant="body1" align="center">
          {mastered}
        </Typography>
      );
    } else {
      subtext2 = this.renderUploadStatus();
      details = (
        <CollapsibleCard
          cardKey="DETAILS"
          title="Details"
          open={this.props.cardState}>

          <NestedListOfWords 
            title="You just reviewed these words:"
            mapOfWordLists={this.props.masteredWords} />

          <NestedListOfWords 
            title="You were unsure about these words:"
            mapOfWordLists={this.props.unsureWords} />

          <NestedListOfWords
            title="You should focus more time on:"
            mapOfWordLists={this.props.incorrectlyAnsweredWords} />

          <NestedListOfWords
            title="Updated Roots:"
            mapOfWordLists={this.props.updatedRoots} />

          <NestedListOfWords
            title="Problems Reported in:"
            mapOfWordLists={this.props.problemReports} />

        </CollapsibleCard>
      );
    }

    return (
      <Stack alignItems="center" sx={{ mt: 6 }} spacing={2} >
        <Typography variant="h5">
          {this.props.title}
        </Typography>
        <Typography variant="body1">
          {this.props.subtitle}
        </Typography>
        {subtext2}
        {this.renderDropdown()}
        <ListDetails listId={this.state.selectedListId} />
        {details}
      </Stack>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: state.loginState.authToken,

    prefetchStatus: state.prefetchState.prefetchStatus,
    summariesByConceptType: state.prefetchState.summariesByConceptType,
    listsByConceptType: state.prefetchState.listsByConceptType,
    listsMetadataById: state.prefetchState.listsMetadataById,

    // Only to be used in the mutation call. Will not be valid
    // elsewhere.
    uploadStatus: state.selectedListState.uploadStatus,
    fetchStatus: state.selectedListState.fetchStatus,
    listId: state.selectedListState.listId,
    subListMetadata: state.selectedListState.subListMetadata,
    conceptCollection: state.selectedListState.conceptCollection,
    totalAnswers: state.selectedListState.totalAnswers,
    correctAnswers: state.selectedListState.correctAnswers,
    startTimeInMillisecs: state.selectedListState.startTimeInMillisecs,
    masteredWords: state.selectedListState.masteredWords,
    unsureWords: state.selectedListState.unsureWords,
    incorrectlyAnsweredWords: state.selectedListState.incorrectlyAnsweredWords,
    updatedRoots: state.selectedListState.updatedRoots,
    problemReports: state.selectedListState.problemReports,
    voiceIndices: state.selectedListState.voiceIndices,

    cardState: state.cardState,
  };
}

const mapDispatchToProps = {
  logReviewedWordsAndPrefetchLandingState,
  prefetchLandingState,
  prefetchWordsReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingScreen);

