import React from 'react';
import { connect } from 'react-redux';

import BrowseCard from './BrowseCard.js';
import Duration from './Duration.js';
import LandingScreen from './LandingScreen.js';
import LoginScreen from './LoginScreen.js';
import RootCard from './RootCard.js';
import SpellingCard from './SpellingCard.js';
import VocabularyCard from './VocabularyCard.js';
import Constants from './Constants.js';

import { CardType } from './ConceptState.js';

class Main extends React.Component {
  render() {
    if (this.props.authToken === null) {
      return (
        <LoginScreen />
      );
    }

    if (this.props.listComplete === null) {
      return (
        <LandingScreen 
          title={"Welcome to " + Constants.webAppName + ", " + this.props.name}
          subtitle={""}
          isStart={true} 
        />
      );
    }

    if (this.props.listComplete === true) {
      const word_list_name = 
        this.props.conceptCollection.getLength() + " " + 
        this.props.subListMetadata.getListName();

      const time_str = 
        (new Duration(Date.now() - this.props.startTimeInMillisecs))
        .readableString();

      return (
        <LandingScreen
          title={"Congratulations, " + this.props.name + "!"}
          subtitle={"You have completed " + word_list_name + " in " + time_str}
          isStart={false}
        />
      );
    }

    const card_type = this.props.conceptState.getCardType();

    if (card_type === CardType.BROWSE) {
      return (
        <BrowseCard />
      );
    } else if (card_type === CardType.VOCAB) {
      return (
        <VocabularyCard />
      );
    } else if (card_type === CardType.SPELLING) {
      return (
        <SpellingCard />
      );
    } else {
      return (
        <RootCard />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    authToken: state.loginState.authToken,
    conceptState: state.conceptState,
    listComplete: state.selectedListState.listComplete,
    name: state.loginState.name,
    conceptCollection: state.selectedListState.conceptCollection,
    startTimeInMillisecs: state.selectedListState.startTimeInMillisecs,
    subListMetadata: state.selectedListState.subListMetadata,
  };
}

export default connect(mapStateToProps)(Main);

