// A data-structure which is first-in, random-out.

export default class RandomQueue {
  constructor(max_length) {
    this.maxLength = max_length;
    this.length = 0;
    this.queue = new Array(max_length).fill(null);
  }

  getLength() {
    return this.length;
  }

  enqueue(entry) {
    if (this.length === this.maxLength) { return false; }

    this.queue[this.length] = entry;
    this.length++;

    return true;
  }

  dequeue() {
    if (this.length === 0) { return null; }

    const random_index = Math.floor(Math.random() * this.length);
    // console.log(random_index);
    const result = this.queue[random_index];
    this.queue[random_index] = this.queue[this.length - 1];
    this.queue[this.length - 1] = null;
    this.length--;

    return result;
  }
}
