export default class OptionData {
  constructor(
    word_data,
    append_variants = false,
    meaning_index = null,
  ) {
    this.wordData = word_data;
    this.word = word_data.spelling;
    this.meaningIndex = (meaning_index !== null) ?
      meaning_index :
      Math.min(
        word_data.meanings.length - 1,
        Math.floor(Math.random() * word_data.meanings.length),
      );


    // Used for roots.
    if (append_variants && word_data.alt.length > 0) {
      this.word += ", " + word_data.alt.join(", ");
    }

    this.meaning = this.makeDisplayable(
      word_data.meanings[this.meaningIndex],
    );

    this.obfuscatedMeaning = this.makeDisplayable(
      word_data.obfuscatedMeanings[this.meaningIndex],
    );
    
    this.partsOfSpeech = this.computePartsOfSpeech(
      word_data.meanings[this.meaningIndex],
    );
  }

  makeDisplayable(meaning) {
    var displayable_meaning = 
      meaning.substr(meaning.indexOf(")") + 1).trim();
    displayable_meaning = 
      displayable_meaning.charAt(0).toUpperCase() + 
      displayable_meaning.slice(1);

    return displayable_meaning;
  }

  isEqual(word_data) {
    return word_data.spelling === this.wordData.spelling;
  }

  getWord() {
    return this.word;
  }

  getDisplayableMeaning(obfuscate) {
    if (obfuscate === true) {
      return this.obfuscatedMeaning;
    } else {
      return this.meaning;
    }
  }

  getPartsOfSpeech() {
    return this.partsOfSpeech;
  }

  computePartsOfSpeech(meaning) {
    if (meaning.charAt(0) !== '(') {
      throw new Error(
        "Error in formatting: " + meaning
      );
    }

    const parts_of_speech_string = meaning.substr(1, meaning.indexOf(')') - 1);
    var tokens = parts_of_speech_string.split(',')
    for (var i = 0; i < tokens.length; i++) {
      tokens[i] = tokens[i].trim();
    }

    return tokens;
  }

  hasCommonPartOfSpeech(option) {
    const pos_set = new Set(option.getPartsOfSpeech());

    for (var i = 0; i < this.partsOfSpeech.length; i++) {
      if (pos_set.has(this.partsOfSpeech[i])) {
        return true;
      }
    }

    return false;
  }
}

