import React from 'react';
import { connect } from 'react-redux';

import Stack from '@mui/material/Stack';

import MultipleChoiceDetails from './MultipleChoiceDetails.js';
import MultipleChoiceInput from './MultipleChoiceInput.js';
import NextWordButton from './NextWordButton.js';
import ReportFeedback from './ReportFeedback.js';
import SpellableScore from './SpellableScore.js';
import SubmitButton from './SubmitButton.js';
import WordNotes from './WordNotes.js';
import WordRoot from './WordRoot.js';
import WordSpelling from './WordSpelling.js';

class RootCard extends React.Component {

  render() {
    var answer = this.props.wordData.spelling; 
    const alternate_spellings = this.props.wordData.alt;
    const notes = this.props.wordData.notes;

    return (
      <Stack alignItems="center">
        <WordSpelling 
          shouldShow={true}
          spelling={answer.toLowerCase()} 
          alt={alternate_spellings}
          renderVariantsInTitle={true} />

        <WordRoot />

        <MultipleChoiceInput
          answerWordData={this.props.wordData}
          answerIsLocked={this.props.answerGiven}
          chosenOption={this.props.chosenOption}
          options={this.props.multipleChoiceOptions} />

        <WordNotes
          word={answer}
          alt={[]}
          examples={this.props.wordData.examples}
          notes={notes}
          shouldShow={this.props.answerGiven} />

        <MultipleChoiceDetails />

        <Stack 
          direction="row"
          sx={{  paddingTop: 2, paddingBottom: 3 }}>

          <SubmitButton />
          <NextWordButton 
            shouldShow={this.props.answerGiven} />

        </Stack>

        <SpellableScore />

        <ReportFeedback />

      </Stack>
    );
  }
}

function mapStateToProps(state) {
  const word_data = state.conceptState.getWordData();

  return {
    wordData: word_data,
    answerGiven: state.currentCardState.answerState.answerGiven,
    answerIsCorrect: state.currentCardState.answerState.answerIsCorrect,
    chosenOption: state.currentCardState.answerState.chosenOption,
    multipleChoiceOptions: state.currentCardState.multipleChoiceOptions,
  };
}

export default connect(mapStateToProps)(RootCard);
